import {Injectable} from '@angular/core';
import {CertificateProofData} from './certificate-proof-data.model';
import {TenantsDBService} from '../services/tenants-db.service';
import {SchoolUsersDbService} from '../services/school-users-db.service';
import {CoursesDBService} from '../services/courses-db.service';


@Injectable({
  providedIn: 'root'
})
export class CertificateProofService {


  constructor(
    private tenantDB: TenantsDBService,
    private userDB: SchoolUsersDbService,
    private coursesDB: CoursesDBService) {

  }

  async loadCertificateProofData(tenantId: string, courseId: string, userId: string): Promise<CertificateProofData> {

    const data: CertificateProofData = {
      tenant: null,
      course: null,
      user: null
    }

    try {

      if (tenantId) {
        data.tenant = await this.tenantDB.findTenantById(tenantId).toPromise();
      }

      if (userId) {
         data.user = await this.userDB.findUserByTenantAndId(tenantId, userId).toPromise();
      }

      if (courseId) {
        data.course = await this.coursesDB.findCourseByTenantAndId(tenantId, courseId).toPromise();
      }

    }
    catch (e) {
      console.log(`Error loading certificate proof data: tenantId=${tenantId} userId=${userId} courseId=${courseId}`, e);
    }
    finally {
      return data;
    }

  }

}
