import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {readCollectionWithIds} from '../common/firestore-utils';
import {LessonQuestion} from '../models/lesson-question.model';
import {first, map} from 'rxjs/operators';
import {from} from 'rxjs';
import {Update} from '@ngrx/entity';
import {Answer} from '../models/answer.model';
import {increment} from '@firebase/firestore';
import { Firestore, collection, collectionData, doc, query, updateDoc, where, writeBatch } from '@angular/fire/firestore';


@Injectable({
  providedIn: "root"
})
export class AnswersDbService {

  constructor(
    private tenant:TenantService,
    private firestore: Firestore) {

  }


  loadAnswers(courseId:string, lessonId:string, questionId:string) {

    const answersPath = this.answersPath(courseId, questionId);

    const constraints = [
      where("lessonId", "==", lessonId)
    ]

    const loadAnswersQuery = query(collection(this.firestore, answersPath), ...constraints)

    const loadAnswersData = collectionData(loadAnswersQuery, {idField: "id"});

    return readCollectionWithIds<Answer[]>(loadAnswersData/*this.afs.collection(answersPath, ref => ref.where("lessonId", "==", lessonId))*/)
      .pipe(
        first(),
        map(lessons => lessons.map(lesson => {return {...lesson, courseId, lessonId}}))
      );
  }


  createNewAnswer(courseId:string, questionId:string, answerId:string, props: Partial<Answer>) {

    const questionPath = this.questionsPath(courseId) + `/${questionId}`;

    const answersPath = this.answersPath(courseId, questionId);

    const batch = writeBatch(this.firestore);

    const questionRef = doc(this.firestore, questionPath);

    batch.update(questionRef, {
      repliesCount: increment(1)
    });

    const answerRef = doc(this.firestore, answersPath + `/${answerId}`);

    batch.set(answerRef, props);

    return batch.commit()
      .then(() => {return {
        id: answerId,
        courseId,
        ...props
      }});
  }

  questionsPath(courseId:string) {
    return this.tenant.path(`courses/${courseId}/questions`);
  }

  answersPath(courseId:string, questionId:string) {
    return this.tenant.path(`courses/${courseId}/questions/${questionId}/answers`);
  }


  deleteAnswer(courseId: any, questionId: string, answerId:string) {

    const questionPath = this.questionsPath(courseId) + `/${questionId}`;

    const answerPath = this.answersPath(courseId, questionId) + `/${answerId}`;

    const batch = writeBatch(this.firestore);

    const answerRef = doc(this.firestore, answerPath);

    batch.delete(answerRef);

    const questionRef = doc(this.firestore, questionPath);

    batch.update(questionRef, {
      repliesCount: increment(-1)
    });

    return batch.commit();
  }


  updateAnswer(courseId:string, questionId:string, update: Update<LessonQuestion>) {
    const updateAnswerDocRef = doc(this.firestore, this.answersPath(courseId, questionId), '' + update.id)
    const updateDocAsync = updateDoc(updateAnswerDocRef, update.changes)

    return from(updateDocAsync);

  }


}
