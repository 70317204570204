import {Component, OnInit} from '@angular/core';
import {AppState} from '../store';
import {createSelector, select, Store} from '@ngrx/store';
import {selectGoogleAnalyticsConfig, selectUserPermissions} from '../store/selectors';
import {filter, tap} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {GoogleAnalyticsConfig} from '../models/google-analytics-config.model';
import {UserPermissions} from '../models/user-permissions.model';
import {environment} from '../../environments/environment';
import {checkIfPlatformSite} from '../common/platform-utils';

declare const ga;


interface GoogleAnalyticsData {
  config: GoogleAnalyticsConfig;
  permissions: UserPermissions;
}

const selectGoogleAnalyticsData = createSelector(
  selectGoogleAnalyticsConfig,
  selectUserPermissions,
  (config, permissions) => {
    return {
      config,
      permissions
    }
  });


@Component({
    selector: "google-analytics-integration",
    template: "",
    standalone: true,
})
export class GoogleAnalyticsIntegrationComponent implements OnInit {

  initialized = false;

  gaTrackingId: string;

  constructor(
    private store: Store<AppState>,
    private router: Router) {

  }

  ngOnInit() {
    this.store
      .pipe(
        select(selectGoogleAnalyticsData),
        filter(data => !!data.permissions && !this.initialized),
        tap(data => this.activateGoogleAnalyticsIntegration(data))
      )
      .subscribe();

    // report router navigations as page views
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        console.log(`Router navigated to ${location.pathname}`);

        // wait for the GTM script to be loaded, then report the initial page view to GA
        setTimeout(() => {
          this.reportPageView();
        }, 10000);

      });
  }

  activateGoogleAnalyticsIntegration({config, permissions}: GoogleAnalyticsData) {

    // if the user is an admin, use the platform tracking Id
    if (permissions.isAdmin || checkIfPlatformSite()) {
      this.gaTrackingId = environment?.google?.googleAnalyticsTrackingId;
      console.log(`Using platform Google Analytics Tracking Id ${this.gaTrackingId}`);
    }
    // otherwise, use the creator tracking Id, if one is available
    else if (config?.gaTrackingId && config.enabled) {
      this.gaTrackingId = config.gaTrackingId;
      console.log(`Using course creator Google Analytics Tracking Id ${this.gaTrackingId}`);
    }

    if (this.gaTrackingId) {
      this.loadGoogleAnalyticsScript(this.gaTrackingId);
      this.initialized = true;
    }

  }

  loadGoogleAnalyticsScript(gaTrackingId:string) {

    // Begin Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j:any=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',gaTrackingId);
    // End Google Tag Manager

  }

  reportPageView() {
    // report page view to Google Analytics
    if (typeof ga != 'undefined') {
      const path = location.pathname;
      console.log(`reporting page view for ${path} to Google Analytics property ${this.gaTrackingId}`);
      ga('set', 'page', path);
      ga('send', path);
    }
    else {
      console.log(`ga is undefined, cannot report page view for ${location.pathname}`);
    }

    // report the page view to Google Tag Manager
    window['dataLayer'] = window['dataLayer'] || [];

    const gtmEvent = {
      'event': 'virtualPageview',
      'pageUrl': window?.location?.toString(),
      'pageTitle': document.title
    }
    console.log(`Reporting GTM event to GTM container ${this.gaTrackingId}`, gtmEvent);

    window['dataLayer'].push(gtmEvent);

  }

}
