import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CoursesDBService} from '../services/courses-db.service';
import {LessonsDBService} from '../services/lessons-db.service';
import {select, Store} from '@ngrx/store';
import {AppState} from './index';
import {SchoolUsersDbService} from '../services/school-users-db.service';
import {TenantService} from '../services/tenant.service';
import {updateLessonOrderCompleted, updateLessonSynchronizedClones} from './lesson.actions';
import {catchError, concatMap, filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {
  isActiveLessonVideoAccessLoaded,
  selectActiveCourse,
  selectActiveLesson,
  selectPendingLessonsReorder
} from './selectors';
import {LessonActions} from './action-types';
import {ProtectedResourceService} from '../services/protected-resource.service';
import {saveResourceAccess} from './protected-resource-access.actions';
import {MessagesService} from '../shared/services/messages.service';
import {LoadingService} from '../shared/services/loading.service';
import {loadDescription} from './description.actions';
import {environment} from "../../environments/environment";


@Injectable()
export class LessonEffects {

  saveLesson$ = createEffect(() => this.actions$
    .pipe(
      ofType(LessonActions.updateLesson),
      withLatestFrom(
        this.store.pipe(select(selectActiveCourse)),
        this.store.pipe(select(selectActiveLesson)),
      ),
      concatMap(([action, activeCourse, activeLesson]) => {

        // nullify sourceLessonId if the course is a synchronized clone.
        if(environment?.features?.cloneCourse && activeCourse?.cloneType === "synchronized") {

          if (activeCourse.cloneType === "synchronized" && activeLesson?.sourceLessonId) {
            action.lesson.changes['sourceLessonId'] = null;
          }

        }

        const obs$ = this.loading.showLoaderUntilCompleted(this.lessonsDB.saveLesson(action.courseId, action.lesson))

        if(environment?.features?.cloneCourse /*&& activeCourse?.cloneType === "synchronized"*/) {

          this.store.dispatch(updateLessonSynchronizedClones({courseId: action.courseId, lesson: action.lesson}));

        }

        return obs$;

      }),
      catchError(err => {
        this.messages.error('Could not save lesson.');
        return throwError(err);
      })
    ),
    {dispatch: false});


  loadUserVideoAccessIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(LessonActions.watchLesson),
      withLatestFrom(
        this.store.pipe(select(isActiveLessonVideoAccessLoaded)),
        this.store.pipe(select(selectActiveCourse)),
      ),
      filter(([action, loaded, course]) => !loaded),
      concatMap(([action,loaded, course]) => this.resources.loadProtectedResourceAccess(course.cloneType === 'read-only' ? course.sourceCourseId : course.id, action.lessonId) ),
      map(videoAccess => saveResourceAccess({videoAccess})),
      catchError(err => {
        const message = 'Could not verify if the user can access the lesson.';
        console.log(message, err);
        this.messages.error(message);
        return throwError(err);
      })
    ));

  loadLessonDescriptionIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(LessonActions.watchLesson),
      map(({lessonId}) => loadDescription({descriptionId: lessonId})),
      catchError(err => {
        this.messages.error('Could not load lesson description.');
        return throwError(err);
      })
    ));


  saveLessonReordering$ = createEffect( ()=> this.actions$
    .pipe(
      ofType(LessonActions.updateLessonOrder),
      withLatestFrom(this.store.pipe(select(selectPendingLessonsReorder))),
      concatMap(([action, changes]) => this.lessonsDB.updateLessons(action.courseId, changes)),
      map(() => updateLessonOrderCompleted()),
      catchError(err => {
        this.messages.error('Could not save the new lessons order.');
        return throwError(err);
      })
    ));


  publishLesson$ = this.createLessonStatusEffect(LessonActions.publishLesson, 'published');

  unpublishLesson$ = this.createLessonStatusEffect(LessonActions.unpublishLesson, 'draft');



  constructor(private actions$: Actions,
              private coursesDB: CoursesDBService,
              private lessonsDB: LessonsDBService,
              private store: Store<AppState>,
              private loading: LoadingService,
              private messages: MessagesService,
              private usersDB: SchoolUsersDbService,
              private tenant: TenantService,
              private resources: ProtectedResourceService) {

  }

  createLessonStatusEffect(actionType, newStatus:string) {
    return createEffect(() => this.actions$
        .pipe(
          ofType(actionType),
          concatMap((action:any) => this.lessonsDB.saveLesson(action.courseId, <any>{
            id: action.lessonId,
            changes: {
              status: newStatus
            }
          })),
          catchError(err => {
            this.messages.error('Could not update lesson status.');
            return throwError(err);
          })
        ),
      {dispatch: false});

  }


}


