import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {AppState} from '../store';
import {createSelector, select, Store} from '@ngrx/store';
import {TenantInfo} from '../models/tenant.model';
import {updateSchoolDetails} from '../store/platform.actions';
import {TenantService} from '../services/tenant.service';
import {selectUser, selectUserPermissions} from '../store/selectors';
import {filter, tap} from 'rxjs/operators';


/**
 *
 * Updates the source tracking for the customer.
 *
 * Tracks the number of times an ad of a certain type was shown to the user.
 *
 */

@Component({
    selector: 'source-tracking',
    template: ``,
    standalone: true
})
export class SourceTrackingComponent implements OnInit {

  constructor(
    private cookies: CookieService,
    private store: Store<AppState>,
    private tenant: TenantService) {

  }

  ngOnInit() {

    const selectData = createSelector(
      selectUser,
      selectUserPermissions,
      (user, permissions) => ({user, permissions})
    );

    this.store
      .pipe(
        select(selectData),
        filter(data => data?.permissions?.isAdmin && data?.user.id == this.tenant?.id ),
        tap(() => {
          this.saveSourceInfo();
          this.saveBlogPageVisits();
        })
      )
      .subscribe();

  }

  saveSourceInfo() {

    const payload = this.cookies.get("OCH_SOURCES");

    console.log(`Checking OCH_SOURCES cookie: ${payload}`);

    if (payload) {

      try {

        const ochSources = JSON.parse(payload);

        const utms = ochSources?.utms;

        if (utms) {

          const changes: Partial<TenantInfo> = {};

          if (utms) {
            changes.utms = utms;
          }

          console.log(`Saving source info: ${JSON.stringify(changes)}`);

          this.store.dispatch(updateSchoolDetails({changes}));
        }

      } catch (error) {
        console.error("Error parsing OCH_SOURCES cookie", error);
      }

    }

  }

  private saveBlogPageVisits() {

    const payload = this.cookies.get("OCH_BLOG_PAGES_VISITED");

    if (payload) {

      try {

        const pagesVisited = JSON.parse(payload);

        if (pagesVisited?.id && pagesVisited?.data) {

          const {id, data} = pagesVisited;

          const blogPagesVisited = this.tenant?.tenant?.blogPagesVisited || {};

          blogPagesVisited[id] = data;

          const changes: Partial<TenantInfo> = {
            blogPagesVisited
          }

          this.store.dispatch(updateSchoolDetails({changes}));
        }

      } catch (error) {
        console.error("Error parsing OCH_BLOG_PAGES_VISITED cookie", error);
      }

    }

  }

}
