import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {CustomJwtAuthService} from './custom-jwt-auth.service';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {selectUser} from '../store/selectors';
import {TenantService} from './tenant.service';
import {delay} from '../common/generic-utils';
import { Auth, idToken, signInWithCustomToken } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  authJwtToken: string;

  constructor(
    private afAuth: Auth,
    private jwtService: CustomJwtAuthService,
    private tenant: TenantService,
    private store: Store<AppState>) {

    idToken(afAuth)
      .subscribe(jwt => {
        this.authJwtToken = jwt;
        console.log(`AUTH Authentication token emitted: ${this.authJwtToken}`);
      });

    // the custom token is valid for 1 hour, so we need to periodically renew it
    // as this is a custom token, Firebase Auth does not renew it automatically
    this.periodicallyRefreshToken()
      .then(() => console.log('AUTH Periodic custom token refresh started'));

  }

  private async periodicallyRefreshToken() {

    do {

      // renew the token every 45 minutes
      await delay(45 * 60 * 1000);

      console.log('AUTH Refreshing custom token...');

      const tenantId = this.tenant.id;

      const user = await this.store.pipe(
        select(selectUser),
        take(1)
      ).toPromise();

      const authJwtToken = await this.jwtService.createCustomJwt(tenantId, user.id).toPromise();

      const result = await signInWithCustomToken(this.afAuth, authJwtToken)

      await this.afAuth.updateCurrentUser(result.user);

    }
    while (true);

  }


}
