import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {CourseActions, UserActivityLogActions} from './action-types';
import {UserActivityLog} from '../models/user-activity-log.model';


export interface UserActivityLogState extends EntityState<UserActivityLog> {
  userActivityLoaded: { [key: string]: boolean };
}


const adapter = createEntityAdapter<UserActivityLog>();

const initialState = adapter.getInitialState({
  userActivityLoaded: {}
});


export const userActivityLogReducer = createReducer(
  initialState,

  on(UserActivityLogActions.userActivityLoaded, (state, action) => {
      const userActivityLoaded = {...state.userActivityLoaded};
      userActivityLoaded[action.courseId] = true;
      return adapter.addMany(
        action.events,
        {
          ...state,
          userActivityLoaded
        }
      );
    }
  ),
  // if the course was purchased and the user happened to have already loaded it, mark the user activity log for reload again
  on(CourseActions.coursePurchased, (state, action) => {
    const userActivityLoaded = {
      ...state.userActivityLoaded
    };
    userActivityLoaded[action.courseId] = false;
    return {
      ...state,
      userActivityLoaded
    }
  }),
);


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


