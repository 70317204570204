import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {from as fromPromise, Observable} from 'rxjs';
import {readDocumentValue} from '../common/firestore-utils';
import {first, map} from 'rxjs/operators';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';


@Injectable()
export class DescriptionsDbService {

  constructor(private tenant: TenantService,
              private firestore: Firestore) {


  }

  loadDescription(descriptionId: string): Observable<string> {
    const descriptionData = docData(doc(this.firestore, this.descriptionsPath + '/' + descriptionId), {idField: 'id'})

    return readDocumentValue<Object>(descriptionData)
      .pipe(
        map(val => val ? val['description'] : undefined),
        first()
      );
  }

  saveDescription(descriptionId:string, description: string): Observable<string> {

    const saveDescriptionDocRef = doc(this.firestore, this.descriptionsPath, descriptionId)

    const saveDescriptionDocAsync = setDoc(saveDescriptionDocRef, {description})

    return fromPromise(saveDescriptionDocAsync)
      .pipe(
        map(() => {
          return description;
        })
      );
  }

  private get descriptionsPath() {
    return this.tenant.path('descriptions');
  }

}
