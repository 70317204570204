import {PageEditorComponent} from '../page-editor.component';
import {inject} from '@angular/core';
import {EditSectionService} from '../services/edit-section.service';
import {openConfirmationDialog} from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';

export const pageEditorDeactivateGuard = (component: PageEditorComponent, state) => {

  const editService = inject(EditSectionService)

  const dialog = inject(MatDialog)

  return new Promise<boolean>((resolve) => {

    if(!editService.isDirty()) {

      // component.ngOnDestroy()
      // editService.setOriginalPage(null)
      editService.reset()

      resolve(true)

      return

    }

    openConfirmationDialog(dialog, "Warning: Unsaved Changes", "Are you sure that you want to discard your changes?", "Yes", "No")
        .subscribe(val => {

          if (val.confirm) {
            resolve(false)

            return

          }
          // editService.setOriginalPage(null)
          editService.reset()
          // component.ngOnDestroy()
          resolve(true)

        });

  })


};


