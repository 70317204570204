import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {CertificateEmbedData} from './certificate-embed-data.model';
import {inject} from '@angular/core';
import {CertificateEmbedService} from './certificate-embed.service';


export const certificateEmbedResolver: ResolveFn<CertificateEmbedData> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(CertificateEmbedService).loadCertificateEmbedData(
      route.paramMap.get('tenantId'),
      route.paramMap.get('courseId'),
      route.paramMap.get('userId')
    );
  };

