import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {EditTeamService} from './edit-team.service';


@Injectable()
export class TeamSizeResolver {

  constructor(private team: EditTeamService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
    return this.team.loadTeamSize();
  }

}
