import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {TenantsDBService} from '../services/tenants-db.service';
import {checkIfPlatformSite, checkIfSingleSignOnPage, DEFAULT_THEME} from '../common/platform-utils';
import {setSessionLanguage, themeChanged} from '../store/platform.actions';
import {first, map, tap} from 'rxjs/operators';
import {AppState} from '../store';
import {Store} from '@ngrx/store';

/**
 *
 * This guard is only applied to the login page. This will apply the tenant brand to the login page in case
 * we are using the single sign-on login page, for example login.onlinecoursehost.com.
 *
 * It's important to load the brand settings before displaying the login page, otherwise the page will look broken for a second.
 *
 * In the case of a login to the platform website, this guard will not do anything because the platform brand is already applied.
 *
 */

@Injectable()
export class LoginGuard  {

  tenantId:string;

  constructor(
    private tenantsDB: TenantsDBService,
    private store: Store<AppState>
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    // if this is the login page of the platform website, apply the platform brand
    if (checkIfPlatformSite()) {
      this.store.dispatch(themeChanged(DEFAULT_THEME));
      this.store.dispatch(setSessionLanguage({language: "en"}));
    }

    this.tenantId = route.queryParamMap.get('tenantId');

    if (!checkIfSingleSignOnPage()) {
      return of(true);
    }
    else {

      return this.tenantsDB.findTenantById(this.tenantId)
        .pipe(
          tap(tenant => {

            if (tenant) {
              this.store.dispatch(themeChanged(tenant.brandTheme));
              if (tenant?.languageConfig?.siteLanguage) {
                this.store.dispatch(setSessionLanguage({language: tenant?.languageConfig?.siteLanguage}));
              }
              else {
                this.store.dispatch(setSessionLanguage({language: "en"}));
              }
            }
            else {
              this.store.dispatch(themeChanged(DEFAULT_THEME));
              this.store.dispatch(setSessionLanguage({language: "en"}));
            }

          }),
            map(tenant => !!tenant),
            first()
        );
    }

  }

}
