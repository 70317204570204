// TODO add any effects related to user activity log actions


import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadingService} from '../shared/services/loading.service';
import {select, Store} from '@ngrx/store';
import {AppState} from './index';
import {CourseActions, UserActivityLogActions} from './action-types';
import {concatMap, filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {UserActivityLogService} from '../services/user-activity-log.service';
import {
  canUserAccessCourse, doesUserOwnCourse, selectActiveCourse, selectTenantInfo,
  selectUser
} from './selectors';
import {combineLatest, from, of} from 'rxjs';
import {userActivityLoaded} from './user-activity-log.actions';
import {selectUserActivityLogState} from './user-activity-log.selectors';
import {selectActiveCourseLessonsWatched} from './user-lesson-status.selectors';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {TenantService} from '../services/tenant.service';
import {StudentsGroup} from "../models/students-group.model";

/**
 * detect CourseActions.courseLoaded action via an effect. If the course has drip active and the drip is based
 * on either enrollment date or start date, load the user activity.
 *
 */

@Injectable({
    providedIn: 'root'
})
export class UserActivityLogEffects {

    loadUserActivityLog$ = createEffect(() => this.actions$
        .pipe(
            ofType(CourseActions.loadCourseDetail),
            withLatestFrom(
                this.store.pipe(select(selectUser)),
                this.store.pipe(select(selectUserActivityLogState)),
                this.store.pipe(select(canUserAccessCourse))
            ),
            filter(([action, user, userActivityLogState, canUserAccessCourse]) => {
                return canUserAccessCourse && !userActivityLogState.userActivityLoaded[action.course.id];
            }),
            // we only need the user activity log if the course has a drip sequence active not based on a specific date, and the user is not anonymous
            filter(([{course}, user]) => {
                return course?.dripConfig?.active && course.dripConfig.trigger != 'specific_date' && user.id != 'anonymous';
            }),
            concatMap(([{course}, user]) => {
                    return from(this.userActivityLogService.loadUserActivityLogForCourse(user.id, course.id)).pipe(
                        map(events => ({courseId: course.id, events}))
                    );
                }
            ),
            map(({events, courseId}) => userActivityLoaded({events, courseId}))
        ));


    /**
     *
     * A course is considered to have been started the first time that the logged in user with no previous lessons watched
     * reaches the course player page.
     *
     * for premium courses, the course is considered started only if the user owns it.
     *
     * for free courses, the course is considered started and the enrollment happens at the same time.
     *
     */

    detectCourseStarted$ = createEffect(() => this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            map((event: NavigationEnd) => event?.url))
        .pipe(
            withLatestFrom(
                this.store.pipe(select(selectUser)),
                this.store.pipe(select(selectActiveCourse)),
                this.store.pipe(select(selectActiveCourseLessonsWatched)),
                this.store.pipe(select(doesUserOwnCourse)),
              this.store.pipe(select(selectTenantInfo))
            ),
            filter(([url, user, course, lessonsWatched, doesUserOwnCourse, tenant]) => {

                    const userIsLoggedIn = user?.id != 'anonymous';

                    const noLessonsWatched = lessonsWatched?.length == 0;

                    //console.log(`path = ${url}`);

                    const urlSegments = url?.split('/');

                    //console.log(`segments = ${urlSegments}`);

                    const userIsOnCoursePlayerPage = urlSegments[urlSegments?.length - 2] == 'lessons';

                    //console.log(` noLessonsWatched=${noLessonsWatched} userIsLoggedIn=${userIsLoggedIn} userIsOnCoursePlayerPage=${userIsOnCoursePlayerPage}`);

                    return userIsLoggedIn && course && noLessonsWatched && userIsOnCoursePlayerPage;
                }
            ),
            map(([action, user, course, lessonsWatched, doesUserOwnCourse, tenant]) => {

                // if the course is premium and the user owns it,
                // consider the course as started when the user watches the first lesson
                if (!course?.free && doesUserOwnCourse) {
                    console.log(`Reporting course started event for premium course.`);

                    // TODO if the user has access to a course via a group, report also the course enrolment event,
                   // and not just course started

                  if(this.checkIfStudentHasAccessToCourseViaGroups(course, user?.groupIds, tenant.studentGroups)) {

                    return combineLatest([
                      from(this.userActivityLogService.reportCourseEnrollment(user.id, course.id)),
                      from(this.userActivityLogService.reportCourseStarted(user.id, course.id))
                    ])
                      .pipe(
                        map((events) => userActivityLoaded({courseId: course?.id, events}))
                      );

                  }

                    return from(this.userActivityLogService.reportCourseStarted(user.id, course.id))
                        .pipe(map((event) => userActivityLoaded({courseId: course?.id, events: [event]})));
                }

                // if the course is free and the user watches the first lesson,
                // we need to report both the enrolment and start events at the same time.
                // this is because for free courses, there is really no enrolment event, anyone can access them
                else if (course?.free) {
                    console.log(`Reporting course enrollment and course started events for FREE course.`);
                    return combineLatest([
                        from(this.userActivityLogService.reportCourseEnrollment(user.id, course.id)),
                        from(this.userActivityLogService.reportCourseStarted(user.id, course.id))
                    ])
                        .pipe(
                            map((events) => userActivityLoaded({courseId: course?.id, events}))
                        );
                }
                return of(userActivityLoaded({courseId: course?.id, events: []}));
            }),
            concatMap((action) => action)
        ));

        private checkIfStudentHasAccessToCourseViaGroups(course, userGroupIds: string[], groups: StudentsGroup[]) {

          const tenantStudentGroups = groups;

          const studentGroupIds = userGroupIds ?? [] as string[];

          // search to see if any studentGroupId is in tenantStudentGroups
          const groupsUserHasAccess = studentGroupIds.map((id: string) => {
            return tenantStudentGroups?.find((group: any) => group.id === id);
          });

          const courseIds: any[] = [];

          groupsUserHasAccess
            .filter(Boolean)
            .forEach((group: any) => {

              courseIds.push(...group.courseIds);

            });

          return courseIds.includes(course?.id);

        }

    constructor(
        private tenantService: TenantService,
        private actions$: Actions,
        private loading: LoadingService,
        private router: Router,
        private store: Store<AppState>,
        private userActivityLogService: UserActivityLogService) {

    }

}
