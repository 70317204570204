import {Component, OnInit} from '@angular/core';
import {createSelector, select, Store} from '@ngrx/store';
import {AppState} from '../store';
import {TenantInfo} from '../models/tenant.model';
import {UserPermissions} from '../models/user-permissions.model';
import {selectTenantInfo, selectUserPermissions} from '../store/selectors';
import {filter, first, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

interface FacebookIntegrationData {
  tenant: TenantInfo,
  permissions: UserPermissions

}

const selectRewardfulIntegrationData = createSelector(
  selectTenantInfo,
  selectUserPermissions,
  (tenant, permissions) => {
    return {
      tenant,
      permissions
    }
  }
);


/**
 *
 * This non-visual component loads the Rewardful integration scripts into the browser.
 *
 * Rewardful is a Stripe Affiliate Program platform, and we use it in two different ways.
 *
 * First, we run our own platform affiliate program with it - anyone bringing new paying course creators to the platform gets a commission.
 *
 * Second, we allow course creators run their own Stripe affiliate program, to bring affiliates to their tenant websites.
 *
 * The integration follows this documentation - https://app.getrewardful.com/setup/code?platform=javascript_browser
 *
 */

@Component({
    selector: "rewardful-integration",
    template: "",
    standalone: true
})
export class RewardfulIntegrationComponent implements OnInit {

  constructor(private store: Store<AppState>) {

  }

  ngOnInit() {

    this.store
      .pipe(
        select(selectRewardfulIntegrationData),
        filter(data => !!data.tenant && !!data.permissions),
        first(),
        tap(data => this.initRewardfulIntegration(data))
      )
      .subscribe();

  }

  private initRewardfulIntegration(data: FacebookIntegrationData) {

    const {permissions, tenant} = data;

    // if the user is an admin, then run the platform affiliate program
    if (permissions.isAdmin) {
      console.log("Loading platform affiliate scripts ");
      this.loadRewardfulScripts(environment.rewardful.platformAffiliateId);
    }

  }

  private loadRewardfulScripts(rewardFulId:string) {

    eval("(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful')");

    /// load the Google Analytics async script
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://r.wdfl.co/rw.js`;
    script.setAttribute("data-rewardful", rewardFulId);
    var head = document.getElementsByTagName('head')[0];
    head.appendChild(script);

  }


}

