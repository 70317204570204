import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {TenantService} from './tenant.service';
import {Injectable} from '@angular/core';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {concatMap, map, tap} from 'rxjs/operators';
import {TenantsDBService} from './tenants-db.service';
import {checkIfPlatformSite, DEFAULT_THEME} from '../common/platform-utils';
import {setTenantInfo, themeChanged} from '../store/platform.actions';
import {Tenant, TenantInfo} from '../models/tenant.model';
import {pricingPlansLoaded} from '../store/pricing-plans.actions';
import {MessagesService} from '../shared/services/messages.service';
import {LoadingService} from '../shared/services/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {FREE_TRIAL_PLATFORM_MODE, isTrialWithCardActive, isTrialWithoutCardActive, PlatformTrialMode} from '../common/ui-constants';
import {isTrialExpired} from '../common/trial-utils';
import { Auth, User, signInWithCustomToken } from '@angular/fire/auth';
import {getUrlParameter} from './routing-utils';


/*
*
*  This guard has a couple of very important functions, without which the website simply cannot function at all.
*
*   - it determines the tenant at application startup time, which will determine which courses
*     and lessons will be shown to the user (as this is a multi-tenant app)
*
*   - it loads the tenant branding styles, without which we cannot even display the website correctly to the user
*
*  - the tenant data is stored in the TenantService, which is then available to the rest of the application
*
*  - the tenant data will include the tenant unique identifier, which is needed in almost every database query
*
*  - this guard will redirect to a trial activation page if needed, depending on the scenario
*
*/

@Injectable()
export class PlatformGuard  {

  constructor(private tenant: TenantService,
              private tenantDB: TenantsDBService,
              private afAuth: Auth,
              private store: Store<AppState>,
              private router: Router,
              private loading: LoadingService,
              private messages: MessagesService,
              private translate: TranslateService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // supported languages
    this.translate.addLangs(['en', 'es']);

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    // login the user using an url JWT parameter, if it arrived via a redirect from the single sign-on login page
    // don't use the router snapshot, as the URL parameters are sometimes not available there
    const authJwtToken = getUrlParameter('authJwtToken');

    if (authJwtToken) {

      console.log('AUTH Signing in with custom JWT ', authJwtToken);

      const result = await signInWithCustomToken(this.afAuth, authJwtToken);

      console.log(`AUTH Setting user profile to:`, result?.user);

      await this.afAuth.updateCurrentUser(result.user);
    }
    else {
      console.log('AUTH No custom JWT token found in the URL parameter authJwtToken, user cannot log in!!');
    }

    // the tenant is only going to be determined once, at application startup time
    // if the tenant is already known, then we can proceed with the navigation
    if (this.tenant.id) {
      return this.determineTargetRoute(this.tenant.tenant, route);
    }

    // otherwise the tenant needs to be determined first
    console.log("Determining tenant Id, site branding and other settings.");

    return new Promise((resolve, reject) =>{

      this.afAuth.onAuthStateChanged(async user => {

        const settings = await this.determinePlatformSettings(user, route);

        resolve(settings);
      });

    });

  }

  async determinePlatformSettings(auth: User, route:ActivatedRouteSnapshot): Promise<boolean | UrlTree> {

    try {

      this.loading.loadingOn();

      const isPlatformSite = checkIfPlatformSite(),
          subDomain = this.getPlatformSubdomain();

      let tenant: Tenant;

      // platform site main app, logged out: no tenant
      if (isPlatformSite && !auth) {
        console.log("Platform website, not authenticated - redirecting to login");
        return this.router.parseUrl('/platform-login');
      }
      // platform site subdomain
      else if (subDomain) {
        console.log(`Finding tenant Id for subdomain ${subDomain}`);
        tenant = await this.tenantDB.findTenantBySubdomain(subDomain).toPromise();
      }
      // if it's not the platform site or a known subdomain, then it must be a custom domain
      else {
        console.log(`Finding tenant Id for custom domain ${window.location.hostname}`);
        tenant = await this.tenantDB.findTenantByCustomDomain(window.location.hostname).toPromise();
      }

      // setting the tenant id globally (determines what courses get loaded)
      if (tenant) {
        this.tenant.id = tenant.id;
        this.tenant.tenant = tenant;
        console.log(`Setting tenant Id as ${tenant.id}`);
        this.saveTenantDetails(tenant);
      }
      else {

        this.messages.error(`Could not load the online school linked to ${window.location.hostname}, subdomain ${subDomain}.`);

        // retry a few times?

//        setTimeout(() => {
//          window.location.reload();
//        }, 3000);


      }

      return this.determineTargetRoute(tenant, route);

    }
    catch(err) {
      const message = `Could not determine tenant Id, cannot load website. ${err}`;
      this.messages.error(message);
      console.error(message);
    }
    finally {
        this.loading.loadingOff();
    }
  }

  getPlatformSubdomain() {

    const hostName = document.location.host;

    // checking if this a tenant subdomain
    const subDomainRegex = new RegExp(`^(.*).${environment.domainName}`);

    const matches = hostName.match(subDomainRegex);

    return matches && matches.length == 2 ? matches[1] : undefined;

  }

  /**
   *
   * Determines the target route of the navigation.
   *
   **/

  determineTargetRoute(tenant: TenantInfo, route:ActivatedRouteSnapshot) {

    // if the user is redeeming a lifetime coupon, allow access
    if (route.queryParamMap.get("redeemLifetimeCoupon")) {
      return this.router.parseUrl(`/platform-lifetime-coupons/redeem-coupon`);
    }

    // if the user is redeeming a lifetime coupon, allow access
    if (route.queryParamMap.get("redeemAppSumoPlan")) {
      console.log(`Redirecting to Appsumo plan redemption page.`)
      return this.router.parseUrl(`/platform-lifetime-coupons/redeem-multi-tier-coupon`);
    }
    else {
      console.log(`This is not a lifetime coupon login, continuing...`)
    }

    // if the user already has a paid plan, allow access
    if (tenant.instructorPricingPlan != 'growth') {
      return true;
    }

    // if a platform plan purchase is ongoing and was successful, allow access
    if (route.queryParamMap.get("purchaseResult") == "success") {
      return true;
    }

    // if the user logged in when activating a lifetime plan, allow access
    if (route.queryParamMap.get("activateLifetimePlan")) {

      let url = `/platform-lifetime-plan/activate-lifetime-plan`;

      let plan = route.queryParamMap.get("plan");

      if (plan) {
        url += `?plan=${plan}`;
      }

      return this.router.parseUrl(url);
    }

    // if the user is trying to access the lifetime plan or lifetime coupon pages, allow access
    if (route?.url?.length) {
      const path = route?.url?.length && route?.url[route?.url?.length - 1]?.path;
      if (path == "platform-lifetime-coupons" || path == "platform-lifetime-plan") {
        return true;
      }
    }

    // if a credit card is needed for a free trial, redirect to start trial page
    if (isTrialWithCardActive() && tenant.instructorPricingPlan == "growth") {
      return this.redirectToTrialPage(route, "start-free-trial-with-card");
    }

    if ( isTrialWithoutCardActive() &&
      tenant.instructorPricingPlan == "growth" &&
      isTrialExpired(tenant)) {
      return this.redirectToTrialPage(route, "start-free-trial-with-card");
    }

    // in all other cases, give access to the target route
    return true;

  }

  redirectToTrialPage(route:ActivatedRouteSnapshot, trialPagePath:string) {
    // if the user is already on the trial page, grant access
    if (route.url.length > 0 && route.url[route.url.length - 1].path.endsWith(trialPagePath)) {
      return true;
    }
    // to any other page, deny access and instead redirect to the trial page
    else {

      let url = `/${trialPagePath}?trial=true`, // filler initial parameter, to make it simple to add more parameters with &
        ppid = route.queryParamMap.get("ppid"),
        accountCreationEvent = route.queryParamMap.get('accountCreationEvent');

      if (ppid) {
        url += `&ppid=${ppid}`;
      }

      if (accountCreationEvent) {
        url += `&accountCreationEvent=${accountCreationEvent}`;
      }

      return this.router.parseUrl(url);
    }
  }

  saveTenantDetails(tenant: Tenant) {

    const tenantInfo: TenantInfo = {
      ...tenant,
      thirdPartyScripts: tenant.thirdPartyScripts ?? [],
      disablePlatformBranding: !!tenant.disablePlatformBranding,
      instructorPricingPlan: tenant.instructorPricingPlan || 'growth',
      currency: tenant.currency || 'USD'
      };

    this.store.dispatch(setTenantInfo({tenantInfo}));

    if (tenant.pricingPlans) {
      this.store.dispatch(pricingPlansLoaded({pricingPlans: tenant.pricingPlans}));
    }

    if (checkIfPlatformSite()) {
      this.setPlatformBrandColors();
      // on the platform site, the only visible page should be the login page, used to identify the tenant.
      // Then after login, the user gets redirected to the tenant website, where it will perform all the admin operations
      this.router.navigate(['/platform-login']);
    } else if (tenant) {
      this.store.dispatch(themeChanged(tenant.brandTheme));
    }

    this.applyCustomFavIcon(tenant);

  }


  setPlatformBrandColors() {
    this.store.dispatch(themeChanged(DEFAULT_THEME));
  }

  private applyCustomFavIcon(tenant:TenantInfo) {

    const favIconUrl = tenant?.faviconConfig?.favIconUrl;

    if (favIconUrl) {
      /// add the favicon to the DOM
      const faviconLink = document.createElement('link');
      faviconLink.type = "image/x-icon";
      faviconLink.rel="icon";
      faviconLink.href = favIconUrl;
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(faviconLink);

    }

  }

}
