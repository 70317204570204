<div class="och-wrapper">
  <ng-container *ngIf="(settings$ | async) as settings">


    <div [innerHTML]="brandStyles(settings)"></div>

    <affiliate-cookie-tracking></affiliate-cookie-tracking>

    <third-party-scripts-injector></third-party-scripts-injector>

    <google-analytics-integration></google-analytics-integration>

    <facebook-integration></facebook-integration>

    <paypal-integration></paypal-integration>

    <instructor-chat></instructor-chat>

    <rewardful-integration></rewardful-integration>

    <source-tracking></source-tracking>

    <mix-panel-integration></mix-panel-integration>

    @defer (when isStudentEnrollmentSettingsPresent) {
      <student-enrolment-dialog-container/>
    }

    <!-- social-media-bar-start -->
    @defer (when (showSocialMediaBar && !isAdminRoute) ) {
      @if (showSocialMediaBar && !isAdminRoute) {
        <social-media-widget/>
      }
    }
    <!-- social-media-bar-end -->


    <!--

    @defer(when settings?.showNewYearUpgradeOffer) {
    @if(settings?.showNewYearUpgradeOffer) {
    <new-year-banner />
    }
    }

    @defer(when settings?.showBlackFridayUpgradeOffer) {
    @if(settings?.showBlackFridayUpgradeOffer) {
    <black-friday-banner />
    }
    }

    -->

    <ng-container *ngIf="settings?.sessionLanguage">

      <div class="top-menu-container">

        @defer(when settings?.showAppSumoUpgradeOffer) {
          <app-sumo-banner *ngIf="settings?.showAppSumoUpgradeOffer" />
        }

        <!-- <top-discount-banner *ngIf="settings?.showTopBanner" /> -->
        <top-menu *ngIf="!isPlatformLogin" />

      </div>

      <div class="app-messages-container">
        <messages></messages>
      </div>

      @defer(when (isAiChatVisible$ | async)) {
      <ai-global-chat></ai-global-chat>
      }

      <main>

        <!--[style.padding-top.px]="settings?.showTopBanner ? 65 : 0"-->

        <router-outlet></router-outlet>
      </main>

    </ng-container>

    <loading [detectLazyLoading]="true"></loading>

  </ng-container>
</div>

<!-- <span class="load-font material-symbols-outlined">play_arrow</span>

<span class=" load-font material-symbols-outlined">delete</span> -->
