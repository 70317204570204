import { Routes} from '@angular/router';
import {LoadCourseDetailResolver} from '../services/load-course-detail.resolver';
import {GetTenantByPathParamGuard} from '../services/get-tenant-by-path-param.guard';


export const courseEmbedRoutes: Routes = [
  {
    path: ':tenantId/:courseUrl',
    loadComponent: () => import('./course-embed.component').then(m => m.CourseEmbedComponent),
    resolve: {
      course: LoadCourseDetailResolver
    },
    canActivate: [GetTenantByPathParamGuard]
  }
];
