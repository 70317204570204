import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {TeamMember} from '../../models/team-member.model';
import {EditTeamService} from './edit-team.service';
import {LoadingService} from '../../shared/services/loading.service';


@Injectable()
export class TeamMembersResolver {

  constructor(private team: EditTeamService, private loading: LoadingService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TeamMember[]> {

    const teamMembers$ = this.team.loadTeamMembers();

    return this.loading.showLoaderUntilCompleted(teamMembers$);

  }




}
