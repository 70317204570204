import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {TenantService} from './tenant.service';
import {LoadingService} from '../shared/services/loading.service';
import {from, Observable} from 'rxjs';
import { Auth, fetchSignInMethodsForEmail } from '@angular/fire/auth';

@Injectable({
  providedIn:'root'
})
export class SignInMethodsResolver  {

  constructor(
    private tenant: TenantService,
    private loadingService: LoadingService,
    private afAuth: Auth) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    const signInMethods$ = from(fetchSignInMethodsForEmail(this.afAuth, this.afAuth.currentUser.email));

    return this.loadingService.showLoaderUntilCompleted(signInMethods$);
  }

}
