<div class="trial-expiration-container" *ngIf="displayUpgradeOption()">
<span class="trial-expiration-text">
    Trial expires in <b>{{ daysLeft }}</b> days
</span>

  <button class="trial-expiration-btn" (click)="onUpgradeClicked()">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clip-path="url(#clip0_2_1701)">
        <path
          d="M2.97426 8.4375C2.70699 8.4375 2.57314 8.11436 2.76213 7.92537L8.78787 1.89963C8.90503 1.78248 9.09497 1.78247 9.21213 1.89963L15.2379 7.92537C15.4269 8.11436 15.293 8.4375 15.0257 8.4375H12.675C12.5093 8.4375 12.375 8.57181 12.375 8.7375V10.3875C12.375 10.5532 12.2407 10.6875 12.075 10.6875H5.925C5.75932 10.6875 5.625 10.5532 5.625 10.3875V8.7375C5.625 8.57181 5.49069 8.4375 5.325 8.4375H2.97426Z"
          stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.375 15.1875H5.625" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.375 12.9375H5.625" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_2_1701">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <span>Upgrade</span>
  </button>
</div>
