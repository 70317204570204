import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {inject} from "@angular/core";
import { select, Store } from "@ngrx/store";
import {combineLatest} from "rxjs";
import {isPermissionsLoaded, selectUser, selectUserPermissions, userState} from '../../../store/selectors';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import { collection, collectionData, Firestore, query, QueryConstraint, where } from "@angular/fire/firestore";
import {isAnonymousUser} from '../../../models/user.model';
import {CustomPage} from '../../../models/page-builder/custom-page.model';
import {UserPermissions} from "../../../models/user-permissions.model";
import {Tenant} from "../../../models/tenant.model";
import {checkIfPlatformSite, getPlatformSubdomain} from "../../../common/platform-utils";
import {TenantsDBService} from "../../../services/tenants-db.service";

export const customPageActivateGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  console.log(`Checking user permissions to access custom page: ${state.url}`)

  const store = inject(Store);
  const router = inject(Router);
  const firestore = inject(Firestore)
  const tenantDB = inject(TenantsDBService)


  const parts = state.url.split("/");
  const pageUrl = parts[parts.length - 1];

  return combineLatest([
    store.pipe(select(selectUser)),
    store.pipe(select(selectUserPermissions)),
    store.pipe(select(isPermissionsLoaded)),
  ]).pipe(
    tap(val => console.log(`Values: `, val)),
    // wait for the permissions to be available in the store if the user is logged in
    filter(([user, userPermissions, isPermissionsLoaded]) => isAnonymousUser(user) || isPermissionsLoaded),
    switchMap(([user, userPermissions, isPermissionsLoaded]) => {
      return getTenant(firestore, userPermissions, tenantDB)
    }),
    switchMap(({tenant, userPermissions}) => {
      return getCustomPages(firestore, tenant, userPermissions, pageUrl)
    }),
    // switchMap(([user, userPermissions, isPermissionsLoaded]) => {
    //   // if (customPageRoute) {
    //   //   return new Observable(subscriber => {
    //   //     subscriber.next({ customPage: customPageRoute, userPermissions });
    //   //     subscriber.complete();
    //   //   });
    //   // }
    //
    //   const customPagesCollection = collection(firestore, `/schools/${user.id}/customPages`);
    //   const customPagesQuery = query(customPagesCollection, ...constraints);
    //
    //   return collectionData(customPagesQuery, { idField: 'id' }).pipe(
    //     map(customPages => {
    //       const customPage = customPages.length > 0 ? customPages[0] : null;
    //       return { customPage, userPermissions } as  { customPage: CustomPage, userPermissions: UserPermissions };
    //     })
    //   );
    // }),
    map(({ customPage, userPermissions }) => {

      if (!customPage) {
        console.log(`Custom page not found for url: ${pageUrl}.`)
        return router.parseUrl('/courses');
      }
      // admins can always preview the page
      if (userPermissions.isAdmin) {
        console.log(`Admin user can access custom page: ${pageUrl}`)
        return true;
      }
      // non-admins can only see the page if it's published
      console.log(`Checking if custom page is published: ${customPage.status}`);
      return customPage.status === 'published' ? true : router.parseUrl('/courses');

    })
  );
};

function getTenant(firestore: Firestore, userPermissions: UserPermissions, tenantDB) {
  const isPlatformSite = checkIfPlatformSite(),
    subDomain = getPlatformSubdomain();

  if(isPlatformSite) {
    console.log(`customPageActivateGuard: Finding tenant Id for platform site ${isPlatformSite}`);
    return getTenantBySubDomain(firestore, userPermissions)

  } else if (subDomain) {
    //  platform site subdomain

    console.log(`customPageActivateGuard: Finding tenant Id for subdomain ${subDomain}`);

    return tenantDB.findTenantBySubdomain(subDomain).pipe(
      map(tenant => {
        console.log(`customPageActivateGuard: Found tenant Id for subdomain ${subDomain}`, tenant);
        return { tenant, userPermissions } as { tenant?: Tenant, userPermissions: UserPermissions };
      })
    );

  } else {

    // custom domain

    console.log(`customPageActivateGuard: Finding tenant Id for custom domain ${window.location.hostname}`);

    return tenantDB.findTenantByCustomDomain(window.location.hostname).pipe(
      map(tenant => {
        console.log(`customPageActivateGuard: Found tenant Id for custom domain `, tenant);

        return { tenant, userPermissions } as { tenant?: Tenant, userPermissions: UserPermissions };
      })
    );

  }

}

function getTenantBySubDomain(firestore: Firestore, userPermissions: UserPermissions) {

  const {
    host, hostname, href, origin, pathname, port, protocol, search
  } = window.location

  const subDomain = host.split(".")[0]

  const constraints: QueryConstraint[] = [
    where("subDomain", "==", subDomain),
  ];

  console.log(`Querying for tenant: ${host}, subDomain: ${subDomain}`);

  const customPagesCollection = collection(firestore, `/tenants/`);
  const customPagesQuery = query(customPagesCollection, ...constraints);

  return collectionData(customPagesQuery, { idField: 'id' }).pipe(
    map(tenants => {
      const tenant = tenants.length > 0 ? tenants[0] : null;
      return { tenant, userPermissions } as { tenant?: Tenant, userPermissions: UserPermissions };
    })
  );

}


function getCustomPages(firestore: Firestore, tenant: Tenant, userPermissions: UserPermissions, pageUrl: string) {

  const constraints: QueryConstraint[] = [
    where("url", "==", pageUrl),
  ];

  const customPagesCollection = collection(firestore, `/schools/${tenant.id}/customPages`);
  const customPagesQuery = query(customPagesCollection, ...constraints);

  return collectionData(customPagesQuery, { idField: 'id' }).pipe(
    map(customPages => {
      const customPage = customPages.length > 0 ? customPages[0] : null;
      return { customPage, userPermissions } as  { customPage: CustomPage, userPermissions: UserPermissions };
    })
  );

}
