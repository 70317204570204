import {Injectable} from '@angular/core';
import {AuthorsDbService} from '../services/authors-db.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AuthorActions} from './action-types';
import {concatMap, filter, map, withLatestFrom} from 'rxjs/operators';
import {isAuthorsLoaded} from './author.selectors';
import {AppState} from './index';
import {select, Store} from '@ngrx/store';
import {authorsLoaded} from './authors.actions';


@Injectable({
   providedIn: "root"
})
export class AuthorsEffects {

  loadAllAuthorsIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthorActions.loadAllAuthors),
      withLatestFrom(this.store.pipe(select(isAuthorsLoaded))),
      filter(([action, loaded]) => !loaded),
      concatMap(([action]) => this.authorsDB.loadAllAuthors()),
      map(authors => authorsLoaded({authors}))
    )
  );

  addNewAuthor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthorActions.upsertAuthor),
      concatMap(action => this.authorsDB.createNewAuthor(action.author))
    )
  , {dispatch: false});


  constructor(
    private actions$: Actions,
    private authorsDB: AuthorsDbService,
    private store: Store<AppState>) {

  }

}
