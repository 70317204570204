import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {TranslateLoader} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {LoadingService} from '../shared/services/loading.service';


export class CustomTranslationsLoader implements TranslateLoader {

  constructor(
    private http:HttpClient,
    private loading: LoadingService) {

  }


  getTranslation(lang: string): Observable<Object> {

    if (environment.production) {

      const cacheBustingParameter = new Date().getTime(),
        url = `https://${environment.platformCloudfrontDomain}/assets/i18n/${lang}.json?cb=${cacheBustingParameter}`;

      console.log(`Loading translations in production from ${url}`);

      const loadTranslations$ = this.http.get(url);

      return this.loading.showLoaderUntilCompleted(loadTranslations$);
    }
    else {

      console.log(`Loading translations in development mode.`);

      const loadTranslations$ = this.http.get(`/assets/i18n/${lang}.json`);

      return this.loading.showLoaderUntilCompleted(loadTranslations$);
    }

  }


}
