import {Injectable} from '@angular/core';
import {TenantService} from './tenant.service';
import {from} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {LessonQuestion} from '../models/lesson-question.model';
import {readCollectionWithIds} from '../common/firestore-utils';
import {Update} from '@ngrx/entity';
import {Timestamp} from '@firebase/firestore';
import { Firestore, QueryConstraint, collection, collectionData, deleteDoc, doc, orderBy, query, setDoc, startAfter, updateDoc, where } from '@angular/fire/firestore';


const QUESTIONS_PAGE_SIZE = 10;



@Injectable({
  providedIn: "root"
})
export class QuestionsDbService {

  constructor(
    private firestore: Firestore,
    private tenant:TenantService) {

  }

  loadQuestions(courseId:string, startAfterNumber: number, filterByLessonId:string = undefined) {

    const questionsPath = this.questionsPath(courseId);

    const constraints : QueryConstraint[] = [
      orderBy('createdAt', 'desc'),
    ]

    if (filterByLessonId) {
      constraints.push(where("lessonId", "==", filterByLessonId))
    }

    if (startAfterNumber) {
      constraints.push(startAfter(Timestamp.fromMillis(startAfterNumber)))
    }

    const questionsQuery = query(
      collection(this.firestore, questionsPath),
      ...constraints
    )

    const questionsData = collectionData(questionsQuery, {idField: 'id'});
    
    return questionsData
      .pipe(
        first(),
        map(lessons => lessons.map(lesson => {return {...lesson, courseId}}))
      );

  }


  createNewQuestion(courseId:string, questionId:string, props: Partial<LessonQuestion>) {

    const questionPath = this.questionsPath(courseId) + `/${questionId}`;

    const addQuestionAsync = setDoc(doc(this.firestore, questionPath), props);

    return from(addQuestionAsync)
      .pipe(
        map(() => {
          return {
            id: questionId,
            courseId,
            ...props
          };
        })
      );
  }

  questionsPath(courseId:string) {
    return this.tenant.path(`courses/${courseId}/questions`);
  }


  deleteQuestion(courseId: any, questionId: string) {

    const questionPath = this.questionsPath(courseId) + `/${questionId}`;

    return from(deleteDoc(doc(this.firestore, questionPath)));
  }


  updateQuestion(courseId:string, update: Update<LessonQuestion>) {

    const ref = updateDoc(doc(this.firestore, this.questionsPath(courseId), '' + update.id), update.changes)

    return from(ref);

  }


}

