import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {DripLessonConfig} from '../models/drip-lesson-config.model';
import {DripLessonsConfigActions} from './action-types';


export interface DripLessonConfigListState extends EntityState<DripLessonConfig> {
  coursesLoaded: { [key: string]: boolean };
}


const adapter = createEntityAdapter<DripLessonConfig>();

const initialState = adapter.getInitialState({
  coursesLoaded: {
    // no courses had their drip config loaded initially
  }
});


export const dripLessonConfigListReducer = createReducer(
  initialState,

  on(DripLessonsConfigActions.dripLessonsConfigLoaded, (state, action) => {

    const coursesLoaded = {
      ...state.coursesLoaded
    }

    coursesLoaded[action.courseId] = true;

    return adapter.addMany(
      action.events,
      {
        ...state,
        coursesLoaded
      }
    )
    }

  )
  )

;


export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();


