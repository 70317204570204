import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {CertificateProofData} from './certificate-proof-data.model';
import {inject} from '@angular/core';
import {CertificateProofService} from './certificate-proof.service';


export const certificateProofResolver: ResolveFn<CertificateProofData> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(CertificateProofService).loadCertificateProofData(
      route.paramMap.get('tenantId'),
      route.paramMap.get('courseId'),
      route.paramMap.get('userId')
    );
  };

