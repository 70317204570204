import {Injectable} from '@angular/core';
import {CommunityPost} from '../../models/community/community-post.model';
import {CommunityPostsService} from '../services/community-posts.service';
import {ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoadingService} from '../../shared/services/loading.service';
import {MessagesService} from '../../shared/services/messages.service';


@Injectable({
  providedIn: 'root'
})
export class LoadPostResolver  {

  constructor(
    private communityPostsService: CommunityPostsService,
    private loading: LoadingService,
    private messages: MessagesService) {

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CommunityPost> {

    try {

      const postId = route.paramMap.get('postId');

      if (!postId) {
        throw `Post id not found.`;
      }

      console.log(`Loading post with id ${postId}`);

      this.loading.loadingOn();

      const post = await this.communityPostsService.getCommunityPost(postId);

      console.log(`Loaded post: `, post);

      if (!post) {
        throw `Post with id ${postId} not found`;
      }

      return post;

    } catch (err) {

      const message = `Failed to load post.`;

      this.messages.error(message);

      console.log(message, err);

      this.loading.loadingOff();
    }

  }

}
