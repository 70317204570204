import {Component, OnInit} from '@angular/core';
import {delay, filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';

declare const mixpanel;

@Component({
  selector: "mix-panel-integration",
  template: ``,
  standalone:true
})
export class MixPanelIntegrationComponent implements OnInit {


  constructor(private router:Router) {
  }

  ngOnInit() {
    // report router navigations as page views to MixPanel
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(4000) // wait for the MixPanel script to be loaded via GTM
      )
      .subscribe(() => {

        console.log(`Router navigated to ${location.pathname}`);

        if (typeof mixpanel != 'undefined' && environment.production) {
          console.log(`Called mixpanel.track_pageview()`);
          mixpanel.track_pageview();
        }
        else {
          console.log(`Could not call mixpanel.track_pageview(), mixpanel not available.`);
        }

      });
  }

}
