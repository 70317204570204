import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Course} from '../models/course.model';
import {Observable} from 'rxjs';
import {courseLoaded, loadCourseDetail} from '../store/course.actions';
import {filter, first, map, tap} from 'rxjs/operators';
import {isUserProfileLoaded, selectAllCourses, selectUser, selectUserPermissions} from '../store/selectors';
import {createSelector, select, Store} from '@ngrx/store';
import {DescriptionsDbService} from './descriptions-db.service';
import {AppState} from '../store';
import {CoursesDBService} from './courses-db.service';
import {LoadingService} from '../shared/services/loading.service';
import {D} from '@angular/cdk/keycodes';
import {UserPermissions} from '../models/user-permissions.model';
import {User} from '../models/user.model';

const courseDetailResolverSelector = createSelector(
  selectAllCourses,
  selectUserPermissions,
  isUserProfileLoaded,
  selectUser,
  (courses, permissions, isProfileLoaded, user) => ({
    courses,
    permissions,
    isProfileLoaded,
    user
  })
)


@Injectable()
export class LoadCourseDetailResolver  {


  constructor(
    private coursesDB: CoursesDBService,
    private descriptionsDB: DescriptionsDbService,
    private router: Router,
    private store: Store<AppState>,
    private loading: LoadingService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Course> {

    const courseUrl = route.paramMap.get('courseUrl');

    return this.store
      .pipe(
        select(courseDetailResolverSelector),
        //filter((data) => data?.isProfileLoaded),
        tap(data => {

          const {courses, permissions} = data;

          let course = this.findCourse(courses, courseUrl);

          // load the course details (sections, lessons, descriptions, etc.)
          if (course) {
            this.store.dispatch(loadCourseDetail({course, courseId: course.id}));
          }
          // if the course is not loaded then load it here
          else if (courseUrl) {

            const isUrlNumeric = /^[0-9]+$/.test(courseUrl);

            // we can find the course either by sequence number or by custom url
            const course$ = !isUrlNumeric ? this.coursesDB.findCourseByUrl(courseUrl) : this.coursesDB.findCourseBySeqNo(parseInt(courseUrl));

            this.loading.showLoader(course$)
              .pipe(
                tap(course => this.store.dispatch(courseLoaded({course})))
              )
              .subscribe();
          }

        }),
        map(data => ({
          ...data,
          course: this.findCourse(data.courses, courseUrl)
        })),
        filter(data => !!data.course),
        tap(data => this.redirectIfUnpublished(data.course, route, data.permissions, data.user)),
        map(data => data.course),
        first()
      );
  }

  findCourse(courses: Course[], urlSegment:string) {

    let course = courses.find(course => course.url == urlSegment);

    return course;
  }

  private redirectIfUnpublished(course: Course, route: ActivatedRouteSnapshot, permissions: UserPermissions, user: User) {
    // always allow admins to view the course, this is essential for previewing unpublished courses
    if (course && permissions?.isAdmin) {
      return true
    }
    if (course?.status !="published" && route?.url[0]?.path == 'courses' && route?.url[1]?.path == course.url) {
      console.log(`Trying to access unpublished course, redirecting to courses page.`)
      this.router.navigateByUrl('/courses');
    }
  }
}
