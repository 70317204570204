import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';
import {LessonActions, ProtectedResourceActions, UserActions} from './action-types';
import {ProtectedResourceAccess} from '../models/protected-resource-access.model';


export interface ProtectedResourceAccessState extends EntityState<ProtectedResourceAccess> {

}

export const adapter: EntityAdapter<ProtectedResourceAccess> = createEntityAdapter<ProtectedResourceAccess>();



export const initialProtectedResourceState: ProtectedResourceAccessState = adapter.getInitialState();


export const protectedResourceAccessReducer = createReducer(
  initialProtectedResourceState,

  on(ProtectedResourceActions.saveResourceAccess, (state, action) => adapter.addOne(action.videoAccess, state)),

  on(ProtectedResourceActions.courseReset, (state, action) =>  {

    const allVideos = Object.values(state.entities),
      courseVideos = allVideos.filter(video => video.courseId == action.courseId),
      courseVideoKeys = courseVideos.map(video => video.id);

    return adapter.removeMany(courseVideoKeys, state);
  }),

  on(UserActions.planActivated, state => adapter.removeAll(state)),

  on(LessonActions.uploadFinished, (state,action) => {
    return adapter.removeOne(action.lessonId, state);
  }),


);





export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();



