import { Routes } from '@angular/router';
import { TeamSizeResolver } from './edit-team/team-size.resolver';
import { TeamMembersResolver } from './edit-team/team-members.resolver';
import { ViewCoursesResolver } from '../services/view-courses.resolver';
import { redirectIfNotAdmin } from '../services/routing-utils';
import { SignInMethodsResolver } from '../services/sign-in-methods.resolver';
import { AuthGuard } from '@angular/fire/auth-guard';
import { EditTeamService } from './edit-team/edit-team.service';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

export const adminRoutes: Routes = [
  {
    path: 'settings',
    loadComponent: () => import('./settings/settings.component').then(m => m.SettingsComponent),
    canActivate: [AuthGuard],
    children: [
      {
        path: 'school-settings',
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        },
        children: [
          {
            path: "",
            redirectTo: "general",
            pathMatch: "full"
          },
          {
            path: "account",
            loadComponent: () => import('./settings/account/account.component').then(m => m.AccountComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "branding",
            loadComponent: () => import('./settings/branding/branding.component').then(m => m.BrandingComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "domain-name",
            loadComponent: () => import('./settings/domain-name/domain-name.component').then(m => m.DomainNameComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "general",
            loadComponent: () => import('./settings/general/general.component').then(m => m.GeneralComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "integrations",
            loadComponent: () => import('./settings/integrations/integrations.component').then(m => m.IntegrationsComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "navigation",
            loadComponent: () => import('./settings/navigation/navigation.component').then(m => m.NavigationComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          },
          {
            path: "email-template",
            loadComponent: () => import('./settings/email-template/email-template.component').then(m => m.EmailTemplateComponent),
            canActivate: [AuthGuard],
            data: {
              authGuardPipe: redirectIfNotAdmin
            }
          }
        ]
      },
      {
        path: 'email-marketing',
        loadComponent: () => import('./email-marketing/email-marketing.component').then(m => m.EmailMarketingComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        },
      },
      {
        path: 'edit-team',
        loadComponent: () => import('./edit-team/edit-team.component').then(m => m.EditTeamComponent),
        resolve: {
          teamMembers: TeamMembersResolver,
          maxTeamSize: TeamSizeResolver
        }
      },
      {
        path: 'manage-admins',
        loadComponent: () => import('./manage-admins/manage-admins.component').then(m => m.ManageAdminsComponent),

      },
      {
        path: 'subscription-coupons',
        loadComponent: () => import('./subscription-coupons/subscription-coupons.component').then(m => m.SubscriptionCouponsComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: 'affiliate-program',
        loadComponent: () => import('./affiliate-program/affiliate-program.component').then(m => m.AffiliateProgramComponent),
        resolve: {
          courses: ViewCoursesResolver
        }
      },
      {
        path: 'edit-bundles',
        loadComponent: () => import('./bundles/edit-bundles.component').then(m => m.EditBundlesComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: 'manage-students',
        loadComponent: () => import('./manage-students/manage-students-container.component').then(m => m.ManageStudentsContainerComponent),
        resolve: {
          courses: ViewCoursesResolver
        },
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        },
      },
      {
        path: 'manage-groups',
        loadComponent: () => import('./manage-students/manage-groups-container.component').then(m => m.ManageGroupsContainerComponent),
        resolve: {
          courses: ViewCoursesResolver
        },
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        },
      },
      {
        path: 'manage-assignments',
        loadComponent: () => import('./manage-assignments/manage-assignments.component').then(m => m.ManageAssignmentsComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: 'bulk-email',
        loadComponent: () => import('./bulk-email/bulk-email.component').then(m => m.BulkEmailComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: 'send-bulk-email',
        loadComponent: () => import('./send-bulk-email/send-bulk-email.component').then(m => m.SendBulkEmailComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: 'request-custom-domain',
        loadComponent: () => import('./school-settings/request-custom-domain/request-custom-domain.component').then(m => m.RequestCustomDomainComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: "setup-zapier-zap",
        loadComponent: () => import('./school-settings/setup-zapier-zap/setup-zapier-zap.component').then(m => m.SetupZapierZapComponent),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      },
      {
        path: "edit-profile",
        loadComponent: () => import('./settings/account/edit-profile/edit-profile.component').then(m => m.EditProfileComponent),
        canActivate: [AuthGuard],
        resolve: {
          signInMethods: SignInMethodsResolver
        },
        data: {
          authGuardPipe: redirectIfNotAdmin
        }
      }
    ],
    providers: [
      TeamMembersResolver,
      TeamSizeResolver,
      EditTeamService,
      {
        provide: MAT_CHIPS_DEFAULT_OPTIONS,
        useValue: {
          separatorKeyCodes: [ENTER, COMMA]
        }
      }
    ],
  },
];