import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LoadingService} from '../shared/services/loading.service';
import {select, Store} from '@ngrx/store';
import {AppState} from './index';
import { CourseActions } from './action-types';
import { concatMap, filter, map, withLatestFrom } from 'rxjs/operators';
import {
  selectUser
} from './selectors';
import {from} from 'rxjs';
import { DripScheduleConfigService } from "../services/drip-schedule-config.service";
import { TenantService } from "../services/tenant.service";
import { dripLessonsConfigLoaded } from "./drip-config-list.actions";
import {selectDripConfigListState} from './drip-config-list-selectors';

@Injectable({
  providedIn: 'root'
})
export class DripConfigListEffects {

  loadDripLessonsConfig$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.loadCourseDetail),
      withLatestFrom(
        this.store.pipe(select(selectUser)),
        this.store.pipe(select(selectDripConfigListState))
      ),
      filter(([action, user, dripConfigListState]) => !dripConfigListState?.coursesLoaded[action.course.id]),
      filter(([{course}, user]) => course?.dripConfig?.active && user.id != 'anonymous'),
      concatMap(([{course}, user]) =>
        from(this.dripScheduleConfigService.getDripScheduleList(this.tenant.id,course.id))
          .pipe(
            map(events => ({courseId: course.id, events}))
          )
      ),
      map(({events, courseId}) => dripLessonsConfigLoaded({events, courseId}))
    ));

  constructor(
    private actions$: Actions,
    private loading: LoadingService,
    private store: Store<AppState>,
    private tenant: TenantService,
    private dripScheduleConfigService: DripScheduleConfigService) {
  }

}
