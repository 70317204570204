import {Component, OnInit} from '@angular/core';
import {AppState} from '../store';
import {createSelector, select, Store} from '@ngrx/store';
import {selectPaypalConfig, selectTenantInfo} from '../store/selectors';
import {filter, first, tap} from 'rxjs/operators';
import {PaypalConfig} from '../models/paypal-config.model';
import {environment} from '../../environments/environment';
import {payPalScriptLoadRequested} from '../store/platform.actions';
import {MessagesService} from '../shared/services/messages.service';
import {checkIfPlatformSite} from '../common/platform-utils';
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: "paypal-integration",
    template: "",
    standalone: true,
})
export class PaypalIntegrationComponent implements OnInit {

  constructor(
    private store:Store<AppState>,
    private messages:MessagesService,
    private route: ActivatedRoute) {

  }

  ngOnInit() {

    const selectPaypalIntegrationData = createSelector(
      selectPaypalConfig,
      selectTenantInfo,
      (paypalConfig, tenant) => {
        return {
          paypalConfig,
          tenant
        }
      }
    );

    this.store
      .pipe(
        select(selectPaypalIntegrationData),
        filter(data => data?.paypalConfig?.enabled && !!data?.paypalConfig?.paypalMerchantId),
        first(),
        tap(data => this.activatePaypalIntegration(data.paypalConfig, data?.tenant?.currency))
      )
      .subscribe();
  }

  activatePaypalIntegration(config: PaypalConfig, currency:string) {
    if (!checkIfPlatformSite()) {

      let paypalPlatformClientId = environment.paypal.platformClientId;

      const testPaypalPlatformId = this.route.snapshot.queryParams['testPaypalPlatformId'];

      if (testPaypalPlatformId) {
        paypalPlatformClientId = testPaypalPlatformId;
      }

      /// load the Paypal SDK script
      var paypalScript = document.createElement('script');
      paypalScript.type = 'text/javascript';
      paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalPlatformClientId}&merchant-id=${config.paypalMerchantId}&currency=${currency}`;
      paypalScript.onerror = () => {
        this.messages.error("Failed to activate Paypal payments, check the Paypal integration parameters, namely the Merchant Id.");
      };
      var head = document.getElementsByTagName('head')[0];
      head.appendChild(paypalScript);

      console.log("Activating Paypal integration");

      this.store.dispatch(payPalScriptLoadRequested());
    }
  }

}
