import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';


if (environment.production) {
  enableProdMode();
}

window.onkeydown = (e) => {
  if (e.keyCode == 32 && e.target == document.body) {
    e.preventDefault();
  }
};

console.log('Bootstrapping Angular ...');
console.log(`Full URL with all parameters: ${window.location.href}`);

bootstrapApplication(AppComponent, appConfig)
  .then(() => console.log('Angular initialized successfully!'))
  .catch(err => console.log(err));
