import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {map, tap} from 'rxjs/operators';
import {EditCourseComponent} from '../edit-course/edit-course.component';
import {AppState} from '../../store';
import {isLessonUploadOngoing} from '../../store/selectors';
import {MessagesService} from '../../shared/services/messages.service';


@Injectable({
  providedIn: 'root'
})
export class UploadOngoingGuard  {

  constructor(
    private store: Store<AppState>,
    private messages: MessagesService) {


  }

  canDeactivate(component: EditCourseComponent,
                currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> {

    return this.store
      .pipe(
        select(isLessonUploadOngoing),
        tap(isUploadOngoing => {
          if (isUploadOngoing) {
            this.messages.warn("Please wait until all uploads are completed and finished processing.");
          }
        }),
        map(isUploadOngoing => !isUploadOngoing)
      );
  }


}
