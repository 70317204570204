import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot,} from '@angular/router';
import {inject} from '@angular/core';
import {SitePagesService} from '../site-pages/services/site-pages.service';
import {CustomPage} from '../../models/page-builder/custom-page.model';


export const getCustomPageResolver: ResolveFn<CustomPage> = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const sitePagesService = inject(SitePagesService);

  const urlParts = state.url.split("/");
  const pageUrl = urlParts[urlParts.length - 1];

  return await sitePagesService.loadSitePageByUrl(pageUrl)
};
