import {Injectable} from '@angular/core';
import {UrlBuilderService} from './url-builder.service';

const preloadedResources = [
  // for the moment, no resources are preloaded
];

@Injectable({
  providedIn: 'root'
})
export class ResourcePreloaderService {

  constructor(private urlBuilder: UrlBuilderService) {
    this.preloadResources();
  }

  private async preloadResources() {
    for (let resource of preloadedResources) {
      //console.log('Preloading resource: ', resource);
      await fetch(this.urlBuilder.buildImageUrl(resource)).catch(err => {
        console.log(`Error preloading resource: ${resource}`);
      });
    }
  }
}
