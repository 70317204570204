import { Routes} from '@angular/router';


export const platformLifetimeCouponsRoutes: Routes = [
  {
    path: 'redeem-coupon',
    loadComponent: () => import('./redeem-platform-lifetime-coupons/redeem-platform-lifetime-coupons.component').then(m => m.RedeemPlatformLifetimeCouponsComponent),
  },
  {
    path: 'redeem-multi-tier-coupon',
    loadComponent: () => import('./redeem-multi-tier-lifetime-coupons/redeem-multi-tier-lifetime-coupons.component').then(m => m.RedeemMultiTierLifetimeCouponsComponent),
  }
];
