import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, tap, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {AppState} from './index';
import {TenantsDBService} from '../services/tenants-db.service';
import {TenantService} from '../services/tenant.service';
import {StripeConnectionService} from '../services/stripe-connection.service';
import {throwError} from 'rxjs';
import {PlatformActions} from './action-types';
import {emailProviderSettingsLoaded} from './platform.actions';
import {selectEmailProviderSettings, selectTenantInfo} from './selectors';
import {MessagesService} from '../shared/services/messages.service';
import {LoadingService} from '../shared/services/loading.service';
import {PlatformPromoService} from '../services/platform-promo.service';
import {StudentEnrollmentSettings} from "../models/student-enrollment.model";


@Injectable()
export class PlatformEffects {

  saveTheme$ = createEffect(() => this.actions$
      .pipe(
        ofType(PlatformActions.saveTheme),
        concatMap(theme => this.tenantsDB.saveTheme(this.tenant.id, theme.primaryColor, theme.accentColor, theme.salesPageBannerColor , theme.salesPageTitleIconColor , theme.salesPageListItemIconColor)),
        catchError(err => {
          this.messages.error('Could not save theme colors.');
          return throwError(err);
        })
      ),
    {dispatch: false});

  saveNewsletter$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(PlatformActions.saveNewsletterFormContent),
        concatMap(action => this.loading.showLoaderUntilCompleted(
          this.tenantsDB.updateTenant(this.tenant.id, {newsletter: action.newsletter})))
      ), {dispatch: false}
  );

  saveSchoolSettings$ = createEffect(() =>
      this.actions$
        .pipe(
          ofType(PlatformActions.updateSchoolDetails),
          concatMap(action =>
            this.loading.showLoaderUntilCompleted(
              this.tenantsDB.updateTenant(this.tenant.id, action.changes)
                .pipe(
                  tap(() => {
                    if (action?.changes?.brandTheme) {
                      this.messages.info(`The new brand is now applied.`);
                    }
                  })
                )
            )
          ),
        )
    , {dispatch: false});

  loadEmailProviderSettings$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(PlatformActions.loadEmailProviderSettings),
        concatMap(() => this.tenantsDB.loadTenantPrivateSettings()),
        map(settings => emailProviderSettingsLoaded({emailProviderSettings: settings?.emailProvider}))
      ));

  saveEmailProviderSettings$ = createEffect(() =>
      this.actions$
        .pipe(
          ofType(PlatformActions.activateEmailMarketingIntegration),
          concatMap(action => this.tenantsDB.activateEmailMarketingIntegration(action?.emailProviderSettings))
        ),
    {dispatch: false});


  cancelEmailProviderIntegration$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(PlatformActions.cancelEmailMarketingIntegration),
        withLatestFrom(this.store.pipe(select(selectEmailProviderSettings))),
        concatMap(([action, settings]) => this.tenantsDB.cancelEmailMarketingIntegration()
        )
      ), {dispatch: false});

  // updateStudentEnrollmentSettings$ = createEffect(() =>
  // this.actions$
  //   .pipe(
  //     ofType(PlatformActions.updateStudentEnrollmentSettings),
  //     withLatestFrom(this.store.pipe(select(selectTenantInfo))),
  //     concatMap(([action, tenant ]) =>
  //       this.loading.showLoaderUntilCompleted(
  //         this.tenantsDB.updateStudentEnrollmentSettings(this.tenant.id,
  //           action.changes,
  //           tenant
  //         )
  //           .pipe(
  //             tap(() => {
  //               this.messages.success("Updated Student Enrollment Settings")
  //             }),
  //             catchError((err) => {
  //               this.messages.error(err?.toString());
  //               return throwError(err);
  //             })
  //           )
  //       )
  //     ),
  //   ), {dispatch: false});

  constructor(private actions$: Actions,
              private tenant: TenantService,
              private store: Store<AppState>,
              private loading: LoadingService,
              private tenantsDB: TenantsDBService,
              private stripeConnectionService: StripeConnectionService,
              private messages: MessagesService,
              private promo: PlatformPromoService) {

  }


}
