import {ActionReducerMap} from '@ngrx/store';
import * as fromCourse from './course.reducer';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import * as fromDescriptions from './descriptions.reducer';
import * as fromCourseSection from './course-section.reducer';
import * as fromLesson from './lesson.reducer';
import {userReducer, UserState} from './user.reducer';
import {platformReducer, PlatformState} from './platform.reducer';
import {couponsReducer, CouponsState} from './coupons.reducer';
import {pricingPlansReducer, PricingPlansState} from './pricing-plans.reducer';
import {contentReducer, ContentState} from './content.reducer';
import {userLessonStatusReducer, UserLessonStatusState} from './user-lesson-status.reducer';
import {latestLessonsReducer, LatestLessonsState} from './latest-lessons.reducer';
import {questionsReducer, QuestionsState} from './questions.reducer';
import {answersReducer, AnswersState} from './answers.reducer';
import {protectedResourceAccessReducer, ProtectedResourceAccessState} from './protected-resource-access.reducer';
import {authorsReducer, AuthorsState} from './authors.reducer';
import {assignmentSolutionsReducer, AssignmentSolutionsState} from './assignment-solution.reducer';
import { userActivityLogReducer, UserActivityLogState } from "./user-activity-log.reducer";
import { dripLessonConfigListReducer, DripLessonConfigListState } from "./drip-config-list.reducer";


export interface AppState {
  courses: fromCourse.State;
  router: RouterReducerState;
  descriptions: fromDescriptions.State;
  sections: fromCourseSection.State;
  lessons: fromLesson.State;
  user: UserState;
  platform: PlatformState;
  coupons: CouponsState;
  resourceAccess: ProtectedResourceAccessState;
  pricingPlans: PricingPlansState;
  content: ContentState;
  userLessonStatus: UserLessonStatusState;
  latestLessonsView: LatestLessonsState;
  questions: QuestionsState;
  answers: AnswersState;
  authors: AuthorsState;
  assignmentSolutions: AssignmentSolutionsState;
  userActivityLog: UserActivityLogState;
  dripLessonsConfig: DripLessonConfigListState
}



export const reducers: ActionReducerMap<AppState> = {
  courses: fromCourse.coursesReducer,
  router: routerReducer,
  descriptions: fromDescriptions.descriptionsReducer,
  sections: fromCourseSection.courseSectionsReducer,
  lessons: fromLesson.lessonReducer,
  user: userReducer,
  platform: platformReducer,
  coupons: couponsReducer,
  resourceAccess: protectedResourceAccessReducer,
  pricingPlans: pricingPlansReducer,
  content: contentReducer,
  userLessonStatus: userLessonStatusReducer,
  latestLessonsView: latestLessonsReducer,
  questions: questionsReducer,
  answers: answersReducer,
  authors: authorsReducer,
  assignmentSolutions: assignmentSolutionsReducer,
  userActivityLog: userActivityLogReducer,
  dripLessonsConfig: dripLessonConfigListReducer
};







