import {Injectable} from '@angular/core';
import {CommunityRoom} from '../../models/community/community-room.model';
import {ManageRoomService} from './manage-room.service';


@Injectable()
export class LoadRoomsResolver  {

  constructor(private manageRoomService: ManageRoomService) {

  }

  async resolve(): Promise<CommunityRoom[]> {
    const data = await this.manageRoomService.loadCommunityRooms() as CommunityRoom[];
    return data
  }



}
