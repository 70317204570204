import {Component, OnInit} from '@angular/core';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {selectUserPermissions} from '../store/selectors';
import {filter, first, tap} from 'rxjs/operators';
import {TenantInfo} from '../models/tenant.model';
import {TenantService} from '../services/tenant.service';

/**
 *
 * This component install the instructor chat on the app, that allows the instructor to ask questions to the app helpdesk.
 *
 * This chat system is only available to instructors or to app admin users in general.
 *
 * The instructors can install their separate chat systems to talk to students, using the Third-Party Scripts option
 * of the Admin Settings / Integrations tab.
 *
 */


@Component({
    selector: "instructor-chat",
    template: "",
    standalone: true
})
export class InstructorChatComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private tenant: TenantService) {

  }

  ngOnInit() {

    this.store.pipe(
      select(selectUserPermissions),
      filter(roles => roles.isAdmin),
      first(),
      tap(() => this.activateInstructorChat())

    )
    .subscribe();

  }

  private activateInstructorChat() {

    const tenant = this.tenant.tenant;

    const isEmbed = window.location.pathname?.includes('course-embed');

    if (isEmbed) {
      console.log(`Skipping customer chat activation for embeds.`);
      return;
    }

    // these code snippets comes from the Help Scout Dashboard
    // API Docs https://developer.helpscout.com/beacon-2/web/javascript-api/
    eval(`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`);

    const Beacon = window["Beacon"];

    // disable chat support if on the free plan
    if (tenant.instructorPricingPlan == 'growth') {
      Beacon('config', {
        messaging: {
          chatEnabled: false
        },
        labels: {
          responseTime: 'During your 30-day trial you have email support. Upgrade for chat support.'
        },
      });
    }

  }
}
