import {Injectable} from '@angular/core';
import {CommunityPost} from '../../models/community/community-post.model';
import {CommunityPostsService} from '../services/community-posts.service';
import {ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoadingService} from '../../shared/services/loading.service';
import {MessagesService} from '../../shared/services/messages.service';

const POSTS_PAGE_SIZE = 5;

@Injectable({
  providedIn: 'root'
})
export class LoadRoomPostsResolver  {

  constructor(
    private communityPostsService: CommunityPostsService,
    private loading: LoadingService,
    private messages: MessagesService) {

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<CommunityPost[]> {

    try {

      const roomId = route.paramMap.get('roomId');

      if (!roomId) {
        throw `Room id not found.`;
      }

      console.log(`Loading room with id ${roomId}`);

      this.loading.loadingOn();

      const posts = await this.communityPostsService.loadCommunityPosts(POSTS_PAGE_SIZE, null, roomId);

      console.log(`Loaded post: `, posts);

      if (!posts) {
        throw `Room with id ${roomId} not found`;
      }

      return posts;

    } catch (err) {

      const message = `Failed to load room.`;

      this.messages.error(message);

      console.log(message, err);
    } finally {

        this.loading.loadingOff();
    }

  }

}
