import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  courseLoaded,
  updateCourseSortOrderCompleted, updateCourseSyncClones,
  updateCourseThumbnailSyncClone,
  userPrivateLoaded
} from "../store/course.actions";
import {catchError, concatMap, filter, map, tap, withLatestFrom} from 'rxjs/operators';
import {CoursesDBService} from '../services/courses-db.service';
import {combineLatest, EMPTY, throwError} from 'rxjs';
import {AppState} from './index';
import {select, Store} from '@ngrx/store';
import {loadDescription} from './description.actions';
import {
  isActiveCourseLessonsLoaded,
  isActiveCourseSectionsLoaded, selectActiveCourse, selectActiveLesson,
  selectPendingCoursesReorder,
  selectPendingSectionsReorder,
  selectUser
} from './selectors';
import {LessonsDBService} from '../services/lessons-db.service';
import {courseLessonsLoaded} from './lesson.actions';
import {SchoolUsersDbService} from '../services/school-users-db.service';
import {TenantService} from '../services/tenant.service';
import {CourseActions, CourseSectionActions} from './action-types';
import {courseSectionsLoaded, updateSectionOrderCompleted} from './course-section.actions';
import {ProtectedResourceService} from '../services/protected-resource.service';
import {MessagesService} from '../shared/services/messages.service';
import {LoadingService} from '../shared/services/loading.service';
import {loadAllAuthors} from './authors.actions';
import { UserActivityLogService } from "../services/user-activity-log.service";
import { Auth, authState } from '@angular/fire/auth';
import {environment} from "../../environments/environment";


@Injectable()
export class CourseEffects {

  createNewCourse$ = createEffect(() => this.actions$
      .pipe(
        ofType(CourseActions.createNewCourse),
        concatMap(({course}) => this.coursesDB.createNewCourse(course))
      ),
    {dispatch: false});

  loadUserPrivate$ = createEffect(() => combineLatest(
    authState(this.afAuth),
    this.tenant.tenantId$
  )
    .pipe(
      filter(([user, tenantId]) => !!(user?.uid && tenantId)),
      tap(data => console.log("loadUserPrivate$ effect called ...", data)),
      concatMap(([user, tenantId]) => this.usersDB.loadUserPrivate(tenantId, user.uid)),
      map(userPrivate => userPrivateLoaded({...userPrivate})),
      catchError(err => {
        console.log('Could not load user private data on loadUserPrivate$ effect...');
        return throwError(err);
      })
    ));

  reloadUserBundles$ = createEffect(() => this.actions$.pipe(
    ofType(CourseActions.bundlePurchased),
    withLatestFrom(
      this.tenant.tenantId$,
      this.store.pipe(select(selectUser)),
    ),
    tap(console.log),
    concatMap(([bundleId, tenantId, user]) => this.usersDB.loadUserPrivate(tenantId, user.id)),
    map(userPrivate => userPrivateLoaded({...userPrivate})),
    catchError(err => {
      this.messages.error('Could not load user bundles.');
      return throwError(err);
    })
  ));

  loadCourseDescriptionIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.loadCourseDetail),
      map(({courseId}) => loadDescription({descriptionId: courseId})),
      catchError(err => {
        this.messages.error('Could not load course description.');
        return throwError(err);
      })
    ));


  loadSectionsIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.loadCourseDetail),
      withLatestFrom(this.store.pipe(select(isActiveCourseSectionsLoaded))),
      filter(([action, loaded]) => !loaded),
      concatMap(
        ([{courseId, course}]) => {

          if(course?.cloneType === "read-only" && course?.sourceCourseId) {
            return this.loading.showLoader(this.lessonsDB.loadCourseSections(course?.sourceCourseId));
          }

          return this.loading.showLoader(this.lessonsDB.loadCourseSections(courseId))
        },
        ([{courseId, course}], courseSections) => {

          const sections = courseSections.map((section) => {
            return {
              ...section,
              courseId: course?.cloneType === "read-only" ? course?.sourceCourseId : courseId,
            }
          });

          return courseSectionsLoaded({ courseSections: sections, courseId })
        }
      ),
      catchError(err => {
        this.messages.error('Could not load sections.');
        return throwError(err);
      })
    ));


  loadLessonsIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.loadCourseDetail),
      withLatestFrom(this.store.pipe(select(isActiveCourseLessonsLoaded))),
      filter(([action, loaded]) => !loaded),
      concatMap(
        ([{courseId, course}]) => {

          if(course?.cloneType === "read-only" && course?.sourceCourseId) {
            return this.loading.showLoader(this.lessonsDB.loadCourseLessons(course?.sourceCourseId))
          }

          return this.loading.showLoader(this.lessonsDB.loadCourseLessons(courseId))
        },
        ([{courseId, course}], lessons) => {

          const currentLessons = lessons.map((lesson) => {
            return {
              ...lesson,
              courseId: course?.cloneType === "read-only" ? course?.sourceCourseId : courseId,
            }
          });

          return courseLessonsLoaded({ lessons: currentLessons, courseId })
        }
      ),
      catchError(err => {
        this.messages.error('Could not load lessons.');
        return throwError(err);
      })
    ));

  loadAuthorsIfNeeded$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.loadCourseDetail),
      map(() => loadAllAuthors()),
      catchError(err => {
        this.messages.error('Could not load authors.');
        return throwError(err);
      })
    ));

  deleteCourse$ = createEffect(() => this.actions$
      .pipe(
        ofType(CourseActions.deleteCourse),
        concatMap(({id}) => this.loading.showLoader(this.coursesDB.deleteCourseDraft(id))),
        catchError(err => {
          this.messages.error('Could not delete the course draft.', err);
          return throwError(err);
        })
      ),
    {dispatch: false});


  saveCourse$ = createEffect(() => this.actions$
      .pipe(
        ofType(CourseActions.updateCourse),
        withLatestFrom(
          this.store.pipe(select(selectActiveCourse)),
        ),
        concatMap(([{course}, activeCourse]) => {

          const obs$ =  this.loading.showLoader(this.coursesDB.saveCourse(course.id, course.changes))

          if(environment?.features?.cloneCourse /*&& activeCourse?.cloneType === "synchronized"*/) {

            // if (activeCourse && activeCourse?.sourceCourseId) {
              this.store.dispatch(updateCourseSyncClones({course}))
            // }

          }

          if(environment?.features?.cloneCourse) {

            if(course?.changes?.thumbnail && course?.changes?.thumbnail?.trim()?.length > 0) {
              this.store.dispatch(updateCourseThumbnailSyncClone({course}))
            }

          }

          return obs$;

        }),
        catchError(err => {
          this.messages.error('Could not save course.');
          return throwError(err);
        })
      ),
    {dispatch: false});


  saveCoursesReordering$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.updateCourseSortOrder),
      withLatestFrom(this.store.pipe(select(selectPendingCoursesReorder))),
      concatMap(([action, changes]) => this.coursesDB.updateCourses(changes)),
      map(() => updateCourseSortOrderCompleted()),
      catchError(err => {
        this.messages.error('Could not save the new course order.');
        return throwError(err);
      })
    ));

  saveCourseSectionReordering$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseSectionActions.updateSectionOrder),
      withLatestFrom(this.store.pipe(select(selectPendingSectionsReorder))),
      concatMap(([{courseId}, changes]) => this.coursesDB.updateCourseSections(courseId, changes)),
      map(() => updateSectionOrderCompleted()),
      catchError(err => {
        this.messages.error('Could not save the new section order.');
        return throwError(err);
      })
    ));

  publishCourse$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.coursePublished),
      concatMap(action => this.coursesDB.saveCourse(action.courseId, {status: 'published', url: action.url})),
      catchError(err => {
        this.messages.error('Error publishing course.');
        return throwError(err);
      })
    ), {dispatch: false});

  unpublishCourse$ = createEffect(() => this.actions$
      .pipe(
        ofType(CourseActions.courseUnpublished),
        concatMap(action => this.coursesDB.saveCourse(action.courseId, {status: 'draft'})),
        catchError(err => {
          this.messages.error('Error unpublishing course.');
          return throwError(err);
        })
      ),
    {dispatch: false});

  reportCourseEnrollmentWhenCoursePurchased$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.coursePurchased),
      withLatestFrom(this.store.pipe(select(selectUser))),
      concatMap(async ([action, user]) => {
          console.log(`Reporting COURSE PURCHASED for user ${user.id} and course ${action.courseId}`)
        return await this.userActivityLogService.reportCourseEnrollment(user.id,action.courseId);
      }),
      catchError(err => {
        this.messages.error('Error purchasing course.');
        return throwError(err);

      } )
    ), {dispatch: false});

  loadSourceReadOnlyCloneCourse$ = createEffect(() => this.actions$
    .pipe(
      ofType(CourseActions.courseLoaded),
      concatMap(action => {
        if (action.course?.cloneType === 'read-only') {
          return this.coursesDB.findCourseByTenantAndId(this.tenant.id, action.course.sourceCourseId)
        }
        return EMPTY
      }),
      tap((course) => {
        if (course) {
          this.store.dispatch(courseLoaded({course}))
        }
      }),
      catchError(err => {
        this.messages.error('Error loading original source course.');
        return throwError(() => err);
      }),
    ), { dispatch: false });


  constructor(private actions$: Actions,
              private coursesDB: CoursesDBService,
              private lessonsDB: LessonsDBService,
              private store: Store<AppState>,
              private loading: LoadingService,
              private messages: MessagesService,
              private afAuth: Auth,
              private usersDB: SchoolUsersDbService,
              private tenant: TenantService,
              private resources: ProtectedResourceService,
              private userActivityLogService: UserActivityLogService) {

  }


}
