import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {AssignmentSolutionsDbService} from '../services/assignment-solutions-db.service';
import {AssignmentSolutionActions, LessonActions} from './action-types';
import {concatMap, filter, map, withLatestFrom} from 'rxjs/operators';
import {AppState} from './index';
import {select, Store} from '@ngrx/store';
import {selectActiveLesson, selectUser} from './selectors';
import {selectActiveLessonAssigmentSolution} from './assigmnent-solutions.selectors';
import {assignmentSolutionLoaded} from './assignment-solutions.actions';


@Injectable()
export class AssignmentSolutionEffects {

  createNewAssignmentSolution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssignmentSolutionActions.assignmentSolutionCreated),
      concatMap(action => this.assignmentSolutionsDB.createNewAssigmentSolution(action.solution)),
      map(solution => assignmentSolutionLoaded({solution}))
    )
  );


  loadAssignmentSolutionIfNeeded$ = createEffect( () =>
    this.actions$.pipe(
      ofType(LessonActions.watchLesson),
      withLatestFrom(
        this.store.pipe(select(selectActiveLesson)),
        this.store.pipe(select(selectActiveLessonAssigmentSolution)),
        this.store.pipe(select(selectUser)),
      ),
      filter(([action, lesson, solution, user]) => lesson.type == "assignment"),
      concatMap(([action, lesson, solution, user]) => this.assignmentSolutionsDB.loadLatestAssignmentSolutionForLesson(lesson.id, user.id)),
      filter(solution => !!solution),
      map(solution => assignmentSolutionLoaded({solution}))
    ));

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private assignmentSolutionsDB: AssignmentSolutionsDbService) {


  }

}
