import {Component, OnInit} from '@angular/core';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {ThirdPartyScript} from '../models/third-party-script.model';
import {selectEmailPopupScript, selectThirdPartyScripts} from '../store/selectors';
import {filter, first, tap} from 'rxjs/operators';
import postscribe from 'postscribe';

/**
 *
 * Injects third-party scripts in the DOM. Each third-party plugin can have multiple script tags.
 *
 * All scripts are injected in the DOM using innerHTML with a custom HTML id, this is just for HTML parsing purposes.
 *
 * Note that in-line scripts injected in the DOM using innerHTML are NOT loaded, and src scripts are not fetched.
 *
 * We can then query the parsed scripts from the DOM, and activate them:
 *
 * - in-line scripts are activated by running eval() on them
 *
 * - src scripts are loaded programmatically form the src address
 *
 */

@Component({
    selector: "third-party-scripts-injector",
    templateUrl: "third-party-scripts-injector.component.html",
    styleUrls: [
        "third-party-scripts-injector.component.scss"
    ],
    standalone: true
})
export class ThirdPartyScriptsInjectorComponent implements OnInit {

  constructor(private store: Store<AppState>) {

  }

  ngOnInit() {
    // apply the email marketing popup, if any
    this.store
      .pipe(
        select(selectEmailPopupScript),
        first(),
        filter(script => !!script),
        tap(script => this.injectScripts([script]))
      )
      .subscribe();
    // apply other third-party scripts
    this.store
      .pipe(
        select(selectThirdPartyScripts),
        first(),
        filter(scripts => !!scripts),
        tap(scripts => this.injectScripts(scripts))
      )
      .subscribe();
  }

  injectScripts(scripts: ThirdPartyScript[]) {
    for (const scriptConfig of scripts) {
      if (scriptConfig.enabled) {
        try {
          postscribe(document.head, scriptConfig.code, {
            releaseAsync:true
          });
        }
        catch (err) {
          console.error(`Error injecting script ${scriptConfig.name}`, err);
        }
      }
    }
  }

}
