import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable,} from 'rxjs';

import {Injectable} from '@angular/core';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {MessagesService} from '../shared/services/messages.service';
import {LoadingService} from '../shared/services/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TenantService} from './tenant.service';
import {TenantsDBService} from './tenants-db.service';
import {map, tap} from 'rxjs/operators';
import {setTenantInfo, themeChanged} from '../store/platform.actions';


@Injectable({
  providedIn: 'root'
})
export class GetTenantByPathParamGuard  {

  constructor(private tenant: TenantService,
              private tenantsDB: TenantsDBService,
              private store: Store<AppState>,
              private router: Router,
              private loading: LoadingService,
              private messages: MessagesService,
              private translate: TranslateService) {

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // supported languages
    this.translate.addLangs(['en', 'es']);

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    const tenantId = route.paramMap.get('tenantId');

    console.log(`Determining tenant Id for the course embed ${tenantId}`);

    return this.tenantsDB.findTenantById(tenantId)
      .pipe(
        tap(tenant => {
          // setting the tenant id globally (determines what courses get loaded)
          if (tenant) {
            this.tenant.id = tenant.id;
            this.tenant.tenant = tenant;
            console.log(`Setting tenant Id as ${tenant.id}`);

            this.store.dispatch(setTenantInfo({tenantInfo: tenant}));
            this.store.dispatch(themeChanged(tenant.brandTheme));
          }
          else {
            const message = `Tenant Id ${tenantId} not found, could not not load course embed.`
            console.log(message);
          }
        }),
        map(tenant => !!tenant)
      );

  }


}
