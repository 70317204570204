import {Component, OnInit} from '@angular/core';
import {
    createInstructorPlanDialog
} from '../../admin/school-settings/change-instructor-plan-dialog/change-instructor-plan-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TenantService} from '../../services/tenant.service';
import {calculateTrialDaysLeft, isTrialExpired} from '../../common/trial-utils';
import {NgIf} from '@angular/common';
import {TenantInfo} from '../../models/tenant.model';
import {concatMap, tap, withLatestFrom} from 'rxjs/operators';
import {select} from '@ngrx/store';
import {selectTenantInfo} from '../../store/selectors';
import {LoadingService} from '../services/loading.service';
import {InstructorPlanService} from '../../admin/school-settings/instructor-plan.service';
import {isTrialWithoutCardActive} from '../../common/ui-constants';

declare const Stripe;

@Component({
    selector: 'trial-expiration',
    templateUrl: './trial-expiration.component.html',
    styleUrls: ['./trial-expiration.component.scss'],
    standalone: true,
    imports: [
        NgIf

    ],

})
export class TrialExpirationComponent implements OnInit {

    tenant: TenantInfo;
    daysLeft: number;

    constructor(
        private dialog: MatDialog,
        private loading: LoadingService,
        private instructorPlanService: InstructorPlanService,
        private tenantService: TenantService) {

    }

    ngOnInit() {
        this.tenant = this.tenantService.tenant;
        this.daysLeft = calculateTrialDaysLeft(this.tenant);

    }


    onUpgradeClicked() {
        createInstructorPlanDialog(this.dialog)
            .pipe(
                concatMap(plan => this.loading.showLoaderUntilCompleted(
                        this.instructorPlanService.updateInstructorPlan(
                            false,
                            this.tenant['email'],
                            plan?.selectedInstructorPlanId,
                            plan?.selectedPlanFrequency
                        )
                    )
                ),
                tap(response => {

                    const stripe = Stripe(response.stripePublicKey);

                    stripe.redirectToCheckout({
                        sessionId: response.sessionId
                    });

                })
            )
            .subscribe();

    }

    displayUpgradeOption() {
        return this.tenant?.instructorPricingPlan == 'growth' &&
          !isTrialExpired(this.tenant) &&
          isTrialWithoutCardActive();
    }

}
