
import {inject} from '@angular/core';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {isAnyAdmin, isUserProfileLoaded, selectTenantInfo, selectUser} from '../store/selectors';
import {catchError, filter, first, map} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {isUserAllowedInCommunity} from './utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {getFullRouterPath} from '../common/router-utils';
import {MessagesService} from '../shared/services/messages.service';
import {CommunitySettings} from '../models/community/community-settings.model';

export const communityAccessGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const messages = inject(MessagesService);
    const store = inject(Store);
    const router = inject(Router);

    console.log(`COMMUNITY GUARD Calling community access guard...`);


    // wait for the user profile to be loaded before deciding to give access to the community

    return combineLatest([
      store.pipe(select(selectUser)),
      store.pipe(select(selectTenantInfo)),
      store.pipe(select(isAnyAdmin)),
      store.pipe(select(isUserProfileLoaded))
    ])
      .pipe(
        filter(([user, tenant, isAnyAdmin, isUserProfileLoaded]) => {
          if (!user || !tenant) {
            return false;
          }
          const communitySettings: CommunitySettings = tenant?.communitySettings;

          // if the user profile is not loaded yet, then we can't decide if the user has access to a paid or invite-only community
          // deny access to the community for now and wait until the user profile gets loaded.

          // if (communitySettings?.communityAccess != 'free' && !isUserProfileLoaded) {
          //   console.log(`COMMUNITY GUARD User profile not loaded yet for community of type ${communitySettings?.communityAccess}, waiting for user profile to load.`);
          //   return false;
          // }

          // all other cases, we are ready to decide if the user has access or not
          return true;
        }),
        map(([user, tenantInfo, isAnyAdmin]) => {

          const currentUser = user;
          const isAdmin = isAnyAdmin;
          const communitySettings: CommunitySettings = tenantInfo?.communitySettings;

          console.log(`COMMUNITY GUARD checking access, currentUser: `, currentUser);
          console.log(`COMMUNITY GUARD checking access, isAdmin: `, isAdmin);
          console.log(`COMMUNITY GUARD checking access, communitySettings: `, communitySettings);

          if (communitySettings?.disabled && isAdmin) {// if the community is disabled and the user is an admin, redirect to the community settings
            console.log(`COMMUNITY GUARD Community is disabled, redirecting to community settings...`);
            return router.parseUrl('/community/settings');
          }

          if (communitySettings?.disabled && !isAdmin) { // if the community is disabled and the user is not an admin, redirect to the courses screen
            console.log(`COMMUNITY GUARD Community is disabled, redirecting to courses screen...`);
            return router.parseUrl('/courses');
          }

          // if user is banned, deny access to the community and redirect to courses page
          if (currentUser?.communityMemberSettings?.banned) {
            messages.error('You do not have access to this community.');
            console.log(`COMMUNITY GUARD User is banned, redirecting to courses page...`);
            return router.parseUrl('/courses');
          }

          const url = getFullRouterPath(route);

          // first check if the community is active
          const isNavigatingToCommunitySettings = url?.includes('community') && url?.endsWith('settings');
          console.log(`COMMUNITY GUARD Is navigating to community settings: ${isNavigatingToCommunitySettings}`, url);

          // if there are no community settings yet, redirect to the settings screen so that the community can be initialized
          if (!communitySettings && !isNavigatingToCommunitySettings) {
            console.log(`COMMUNITY GUARD Community not initialized yet, redirecting to settings screen.`);
            return router.parseUrl('/community/settings');
          }

          // then check if it's a paid or invite-only community.
          // In that case, the user should always be allowed to the home screen
          const isNavigatingToCommunityHome = url?.endsWith('home');

          console.log(`COMMUNITY GUARD Is navigating to community home: ${isNavigatingToCommunityHome}`, url);

          if (isNavigatingToCommunityHome && (
            communitySettings?.communityAccess == 'community-subscription' ||
            communitySettings?.communityAccess == 'invite-only')) {
            console.log(`COMMUNITY GUARD Community is paid or invite-only, allowing access to home screen.`);
            return true;
          }

          // if it's not any of the previous special cases, then check if the user is allowed
          const isAllowed = isUserAllowedInCommunity(communitySettings, isAdmin, currentUser);

          if (isAllowed) {
            console.log(`COMMUNITY GUARD User is allowed in this route.`);
            return true;
          }
          else {
            console.log(`COMMUNITY GUARD User is NOT allowed in this route...`);
          }

          // if the user is not allowed, not sure what this scenario is, as we should have covered all cases before
          // send the user outside the community and give a generic error
          messages.error('You do not have access to this page.');
          console.log(`COMMUNITY GUARD access denied, unknown scenario, redirecting to courses screen...`);
          return router.parseUrl('/courses');
        })
      );
  };

