import {Injectable} from '@angular/core';
import {AppState} from '../store';
import {Store} from '@ngrx/store';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {checkIfPlatformSite, DEFAULT_THEME} from '../common/platform-utils';
import {setSessionLanguage, themeChanged} from '../store/platform.actions';


/**
 *
 * This guard applies the default platform brand to certain pages of the platform website, such as the login page.
 *
 *
 */

@Injectable()
export class DefaultBrandGuard  {

  constructor(private store: Store<AppState>) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // if this is the login page of the platform website, apply the platform brand
    if (checkIfPlatformSite()) {
      this.store.dispatch(themeChanged(DEFAULT_THEME));
      this.store.dispatch(setSessionLanguage({language: "en"}));
    }

    return true;

  }


}
