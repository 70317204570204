import {ANONYMOUS_USER, User} from '../models/user.model';
import {UserPermissions} from '../models/user-permissions.model';
import {createReducer, on} from '@ngrx/store';
import {UserActions} from './action-types';


export interface UserState {
  isLoggedIn: boolean;
  user: User;
  userProfileLoaded: boolean;
  subscriberInfoMessageShown: boolean;
  permissions?: UserPermissions;
  isPermissionsLoaded?: boolean;
}

const DEFAULT_PERMISSIONS: UserPermissions = {
  isAdmin: false,
  isPlatformAdmin: false
};

export const initialUserState: UserState = {
  isLoggedIn: false,
  user: ANONYMOUS_USER,
  userProfileLoaded: false,
  subscriberInfoMessageShown: false,
  permissions: DEFAULT_PERMISSIONS,
  isPermissionsLoaded: false
};


export const userReducer = createReducer(
  initialUserState,

  on(UserActions.login, (state, user) => {
    return {
      ...state,
      isLoggedIn: true,
      user
    };
  }),

  on(UserActions.setUserPermissions, (state, permissions) => {
    return {
      ...state,
      permissions,
      isPermissionsLoaded: true
    };
  }),

  on(UserActions.logout, (state, action) => {
    return {
      isLoggedIn: false,
      user: ANONYMOUS_USER,
      permissions: DEFAULT_PERMISSIONS,
      userProfileLoaded: true,
      subscriberInfoMessageShown: false
    };
  }),

  on(UserActions.userLoaded, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.user,
      },
      userProfileLoaded: true
    };
  }),

  on(UserActions.planActivated, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.user,
        pricingPlan: action.selectedPlan
      }
    };
  }),

  on(UserActions.planCancelled, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        planEndsAt: action.planEndsAt
      }
    };
  }),

  on(UserActions.subscriberInfoMessageShown, (state, action) => {
    return {
      ...state,
      subscriberInfoMessageShown: true
    };
  }),

  on(UserActions.updateNewsletterStatus, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        unsubscribeFromNewsletter: action.unsubscribeFromNewsletter
      }
    };
  }),

  on(UserActions.updateUserLanguage, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        preferredLanguage: action.language
      }
    }
  }),

  on(UserActions.userDetailsUpdated, (state, action) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...action.changes
      }
    }
  })



);


