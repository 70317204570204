import { Routes} from '@angular/router';


export const platformLifetimePlanRoutes: Routes = [
  {
    path: 'activate-lifetime-plan',
    loadComponent: () => import('./activate-lifetime-plan/activate-lifetime-plan.component').then(m => m.ActivateLifetimePlanComponent),
  }
];

