import { __awaiter, __generator } from "tslib";
var subtleCryptoMethods = ["decrypt", "digest", "encrypt", "exportKey", "generateKey", "importKey", "sign", "verify"];
export function supportsWebCrypto(window) {
  if (supportsSecureRandom(window) && typeof window.crypto.subtle === "object") {
    var subtle = window.crypto.subtle;
    return supportsSubtleCrypto(subtle);
  }
  return false;
}
export function supportsSecureRandom(window) {
  if (typeof window === "object" && typeof window.crypto === "object") {
    var getRandomValues = window.crypto.getRandomValues;
    return typeof getRandomValues === "function";
  }
  return false;
}
export function supportsSubtleCrypto(subtle) {
  return subtle && subtleCryptoMethods.every(function (methodName) {
    return typeof subtle[methodName] === "function";
  });
}
export function supportsZeroByteGCM(subtle) {
  return __awaiter(this, void 0, void 0, function () {
    var key, zeroByteAuthTag, _a;
    return __generator(this, function (_b) {
      switch (_b.label) {
        case 0:
          if (!supportsSubtleCrypto(subtle)) return [2 /*return*/, false];
          _b.label = 1;
        case 1:
          _b.trys.push([1, 4,, 5]);
          return [4 /*yield*/, subtle.generateKey({
            name: "AES-GCM",
            length: 128
          }, false, ["encrypt"])];
        case 2:
          key = _b.sent();
          return [4 /*yield*/, subtle.encrypt({
            name: "AES-GCM",
            iv: new Uint8Array(Array(12)),
            additionalData: new Uint8Array(Array(16)),
            tagLength: 128
          }, key, new Uint8Array(0))];
        case 3:
          zeroByteAuthTag = _b.sent();
          return [2 /*return*/, zeroByteAuthTag.byteLength === 16];
        case 4:
          _a = _b.sent();
          return [2 /*return*/, false];
        case 5:
          return [2 /*return*/];
      }
    });
  });
}
