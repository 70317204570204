import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppState} from '../store';
import {select, Store} from '@ngrx/store';
import {selectSessionLanguage} from '../store/selectors';
import {SupportedLanguage} from '../models/supported-language.model';


@Injectable()
export class SessionLanguageInterceptor implements HttpInterceptor {

  sessionLanguage: SupportedLanguage;

  constructor(private store:Store<AppState>) {

    store
      .pipe(
        select(selectSessionLanguage)
      )
      .subscribe(sessionLanguage => this.sessionLanguage = sessionLanguage);

  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.sessionLanguage && (req.method == 'POST' || req.method == "PUT" || req.method == "DELETE") ) {
      const cloned = req.clone({
        body: {
          ...req.body,
          sessionLanguage: this.sessionLanguage
        }
      });
      return next.handle(cloned);
    }
    else {
      return next.handle(req);
    }

  }

}
